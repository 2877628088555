@import "../../theme/theme"

// Modal Preferences styles
.modalHeader
  text-align: center

.modalTitle
  font-size: 20px

.modalText
  color: $darkGray
  font-size: 14px

.modalTextBig
  @extend .modalText
  margin-top: 10px
  margin-bottom: 10px!important
  font-size: 18px

.modalCheckbox
  margin-left: 0!important

.bigButtons
  text-align: center

.textArea
  margin-top: 20px!important

.date
  display: block
  color: $darkGray

.buttonActions
  text-align: right

.modalPreferences
  \:global .ant-modal-footer
    border-top: none

.question
  margin-top: 16px
  margin-bottom: 16px
