@import "../../../theme/theme"

.card
  display: inline-flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  min-width: $additionalInfoCardWidth
  width: $additionalInfoCardWidth
  padding: 14px
  text-align: center
  border-radius: 6px
  background-color: rgba(243, 246, 248, 0.8)
  min-height: 200px
  vertical-align: top
  margin: 50px 0 20px


.title
  font-weight: 600
  font-size: 16px
  margin-bottom: 20px
  color: $black

.label
  font-size: 14px
  color: $darkGray
  line-height: 1
  padding: 0
  margin-bottom: 5px

.text
  font-size: 16px
  color: $black
