
@import "src/theme/theme"
.container
    .description:global.ant-typography
        padding: 8px 0
        border-color: #F1F3F5
        border-style: solid
        border-width: 1px 0
        color: $darkGray
    .continueButton
        font-weight: bold
    .done
        background-color: $success
        pointer-events: none
        cursor: not-allowed !important
    .imageSection
        display: flex
        justify-content: space-between
        .coverImage
            margin-bottom: 10px
            height: 100px
            img
                height: 100%
                object-fit: fill

@media screen and (max-width: 768px)
    .qr
        display: none
