.container
    width: 100%
    padding: 0 20px
    .uploadTitle
        margin-top: 24px
    .qrContainer
        display: flex
        text-align: center
        align-items: center
        background-color: #E6E6E6
        width: 25vw
        padding: 20px
        justify-content: center
        .link
            text-decoration: none
            display: flex
            align-items: center
            justify-content: center
            font-weight: 600
            color: #0057FF
            .icon
                margin: 0
                margin-right: 5px
        & > div
            margin-right: 10px

@media screen and (max-width: 900px)
    .container .qrContainer
        width: calc(100% - 40px)
