@import "../../../../theme/theme"

.notification
  margin-bottom: 25px
  box-shadow: none
  border: none

.checkbox, .organization
  margin-top: 10px !important
  margin-bottom: 10px !important
  font-weight: 500
  color: #273345 !important
  font-size: 16px

.addButton
  margin-top: 30px

.title
  font-weight: 500
  margin-top: 16px
  margin-bottom: 16px

.deletedOrganizationText
  color: $lightGray

