.logoStyle
    background: #f3f6f8
    height: auto
    width: auto
    border-radius: 22px
    align-item: center
    justify-content: center
    padding: 20px
    margin-top: 15px
    margin-bottom: 15px
    place-items: center
    text-align: center

.logoColum1
    font-size: 18px
    align-item: center
    justify-content: center

.logoColum2
    border-left: 2px solid #3d83f7
    font-size: 18px
    align-item: center
    justify-content: center

.organizationLogo
    border-radius: 10px
    box-shadow: 1px 1px 5px grey
    margin-right: 5px
