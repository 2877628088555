@import "../../../../../theme/theme"

.group
  display: flex
  align-items: center
  width: calc(100% - 28px)
  padding: 10px 14px
  border-bottom: 1px solid $gray
  cursor: pointer

.group:first-child
  padding-top: 0

.groupIcon
  position: relative
  padding: 10px
  min-width: 40px
  height: 40px
  background-color: #efefef
  border-radius: 50px

.groupIcon i
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  margin: 0
  width: 16px
  height: 16px

.groupInfo
  display: flex
  flex-direction: column
  width: 100%
  margin-left: 16px

.groupTitleContainer
  display: flex
  justify-content: space-between
  align-items: center

.groupTitle
  width: 100%
  font-weight: 600
  font-size: 16px
  color: $black

.groupDate
  font-size: 12px
  color: $black
  opacity: 0.5
  white-space: nowrap

.groupMessage
  opacity: 0.5
  font-size: 12px
