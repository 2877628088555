$black: #2C3440
$darkGray: #606873

.container
  display: flex
.title
  font-size: 24px
  color: $black

.container .text
  color: $darkGray
  white-space: pre-line

.manImage
  object-fit: cover
  height: 300px

@media screen and (max-width: 767px)
  .manImage
    display: none
