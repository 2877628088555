$nunito-font-path: './fonts/Nunito'

@font-face
  src: url("#{$nunito-font-path}/NunitoSansExtraLight.ttf") format('truetype')
  font-family: 'Nunito Sans'
  font-style: normal
  font-weight: 200

@font-face
  src: url("#{$nunito-font-path}/NunitoSansExtraLightItalic.ttf") format('truetype')
  font-family: 'Nunito Sans'
  font-style: italic
  font-weight: 200

@font-face
  src: url("#{$nunito-font-path}/NunitoSansLight.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 300

@font-face
  src: url("#{$nunito-font-path}/NunitoSansLightItalic.ttf") format('truetype')
  font-family: 'Nunito Sans'
  font-style: italic
  font-weight: 300

@font-face
  src: url("#{$nunito-font-path}/NunitoSansRegular.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 400

@font-face
  src: url("#{$nunito-font-path}/NunitoSansItalic.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: italic
  font-weight: 400

@font-face
  src: url("#{$nunito-font-path}/NunitoSansMedium.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 500

@font-face
  src: url("#{$nunito-font-path}/NunitoSansMediumItalic.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: italic
  font-weight: 500

@font-face
  src: url("#{$nunito-font-path}/NunitoSansSemiBold.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 600

@font-face
  src: url("#{$nunito-font-path}/NunitoSansSemiBoldItalic.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: italic
  font-weight: 600

@font-face
  src: url("#{$nunito-font-path}/NunitoSansBold.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 700

@font-face
  src: url("#{$nunito-font-path}/NunitoSansBoldItalic.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: italic
  font-weight: 700

@font-face
  src: url("#{$nunito-font-path}/NunitoSansExtraBold.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 800

@font-face
  src: url("#{$nunito-font-path}/NunitoSansExtraBoldItalic.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: italic
  font-weight: 800

@font-face
  src: url("#{$nunito-font-path}/NunitoSansBlack.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 900

@font-face
  src: url("#{$nunito-font-path}/NunitoSansBlackItalic.ttf") format('truetype')
  font-family: "Nunito Sans"
  font-style: italic
  font-weight: 900
h1,h2,h3,h4,h5
  margin-block-start: 0
  margin-block-end: 0
body
  margin: 0
*
  font-family: "Nunito Sans"

fieldset
  border: none
