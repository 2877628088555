.centerContent
  display: flex !important
  align-items: center !important
  justify-content: center !important

.preCallWindow
  background-color: black
  border-radius: 8px
  width: 100%
  height: 100%
  min-height: 60vh
  max-width: 700px

.preCallSection
  margin: 50px auto 0
  width: 80vw

.precallVideo
  min-height: 50vh !important
  max-width: 80vw

.cameraIcon
  color: white !important
  font-size: xx-large

.deleteIcon
  cursor: pointer
  position: absolute
  top: 10px
  z-index: 1000

.container
  display: flex
  margin: 10px auto

.videoSection
  flex: 1

.questionnaire
  min-width: 400px
  width: 400px
  background-color: #f3f6f8
  padding: 16px
  margin: 10px auto

.questionnaireScrollBar
  height: 65vh
  overflow-y: auto

.questionnaire .title
  display: flex
  justify-content: space-between
  align-items: center
  font-weight: bold
  font-size: 20px
  margin-left: 8px
  margin-bottom: 24px

  & span
    cursor: pointer

.topLeft
  position: absolute
  left: 10px
  top: 10px
  z-index: 10

.topRight
  position: absolute
  right: 10px
  top: 10px
  z-index: 10

.text
  margin-bottom: 20px
