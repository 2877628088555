.container
  padding: 15px
  width: calc( 100% - 30px )
  border: 1px solid #E7E9ED
  transition: border-color ease-in-out 0.2s

.container.dragover,
.container:hover,
.container:focus
  border-color: #1C6EF7
  cursor: pointer
  outline: none

.loading
  pointer-events: none

.content
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.text
  font-size: 14px
  line-height: 18px
  color: #2C3440
  text-transform: uppercase
  font-weight: bold

.text a
  font-weight: 600
  color: #1C6EF7
  cursor: pointer
  text-decoration: none

.size
  font-size: 12px
  line-height: 14px
  color: #A7AEB8

.progress .text
  position: relative
  margin-right: 40px

.progress .text::after
  position: absolute
  right: -21px
  top: 50%
  margin-top: -1px
  content: ''
  width: 2px
  height: 2px
  border-radius: 50%
  background-color: #A7AEB8

.progress .text,
.progress .time
  color: #A7AEB8
  font-size: 12px
  letter-spacing: 0
  line-height: 16px

.bar
  position: absolute
  left: 0
  bottom: -1px
  background-color: #D2E2FD
  border-bottom-right-radius: 6px
  border-bottom-left-radius: 6px

.inner
  background-color: #1C6EF7
  border-bottom-right-radius: 0
  border-bottom-left-radius: 6px

.inner.done
  border-bottom-right-radius: 6px

.bar, .inner
  width: 100%
  height: 3px

.error .text,
.error .again
  font-size: 14px
  letter-spacing: 0
  line-height: 18px

.error .text
  color: #2C3440
  text-align: center

.error .again
  color: #1C6EF7
  font-weight: 600
