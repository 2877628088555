.actionBtnDiv {
    display: flex;
    justify-content: center;
    align-items: center;

    .actionBtn {
        color: #fff;
        background: #4096ff;
        border: none;
        outline: none;
        font-weight: bold;

        &:hover {
            color: #eee !important;
            background: darken($color: #4096ff, $amount: 10) !important;
        }
    }

    .disabledActionBtn {
        background-color: #8DB4EF;
        pointer-events: none;
    }
}