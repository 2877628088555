.container
  display: flex
  align-items: flex-end

.rate:global.ant-rate
  font-size: 35px !important
  margin: 0 40px

.rate:global.ant-rate .ant-rate-star:not(:last-child)
  margin-right: 15px !important

.rate:global.ant-rate .ant-rate-star-full,
.rate:global.ant-rate .ant-rate-star-first,
.rate:global.ant-rate .ant-rate-star-second
  color: #1069fb !important

.title
  color: #a7aeb8
  font-size: 12px
  font-weight: 500
  width: 80px
  text-align: center
  &:first-of-type
    align-self: flex-start
