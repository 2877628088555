
@import "src/theme/theme"

.radioContainer
    display: flex
    align-items: center
    width: 100%
    :global .ant-form-item-row
        width: 100%
    .description
        margin-left: 8px
    :global .ant-typography
        margin-bottom: 0
        margin-right: 6px
    .box
        margin-bottom: 10px
    img
        border-radius: 8px
        width: 100%
        object-fit: cover
    .coverImage
        width: 100%
        margin-top: 10px
    .optionsContainer
        margin-bottom: 8px
        display: flex
        flex-wrap: wrap
        gap: 16px
        .option
            flex-basis: 30%
            min-width: 300px
            flex-grow: 1
            background-color: #fff
            cursor: pointer
            border-radius: 8px
            border: 1px solid $lightGray
            transition: border .3s linear
            text-align: center
            display: flex
            flex-direction: column
            padding: 8px
            &:hover
                border-color: $primary
                font-weight: 600
            &.selected
                background-color: rgba(176, 240, 210, 0.2)
                border-color: $success
            &.selected
                font-weight: 600
            .content
                padding: 8px 16px

@media screen and (max-width: 768px)
    .radioContainer
        :global .ant-col
            flex-grow: 1
        .options .option
            flex-direction: row
            justify-content: flex-start
            align-items: center
            img
                width: 30%
