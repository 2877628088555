.container
  position: relative
  :global .ant-input
    font-size: 16px
    line-height: 18px
    cursor: pointer
    line-height: 21px
    letter-spacing: 0
    padding: 21px 11px 8px 11px
  .label
    position: absolute
    width: 80%
    text-align: center
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 1
    font-size: 16px
    line-height: 18px
    font-weight: normal
    pointer-events: none
    will-change: top, left, transform, font-size, line-height
    transition-property: all
    transition-duration: 0.2s
    transition-timing-function: ease-in-out
    &.left
      width: fit-content
      left: 11px
      transform: translate(0, -50%)
    &.floating
      width: fit-content
      font-weight: 500
      font-size: 10px
      line-height: 13px
      top: 6px
      left: 11px
      transform: translate(0, 0)
