.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.questionList 
  width: 600px

.participationButtons 
  display: flex
  flex-direction: row
  .participateButton
    margin-left: 10px
  