
@import "src/theme/theme"

.product
    padding: 20px
    border: 1px solid $gray
    border-radius: 12px
    cursor: pointer
    margin-bottom: 20px
    &:last-child
        margin-bottom: 0px
    &.checked
        border-color: $blue
        transition: .5s
    &:hover
        box-shadow: 0 11px 19px -19px
        transform: scale(1.01, 1.01)
        transition: all .3s ease-out
    .title:global.ant-typography
        color: $blue
        margin: 0
    .body
        margin: 10px 0
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        align-items: flex-start
        & > div:first-child
            min-width: 200px
            min-height: 250px
            margin-right: 20px
        .image
            width: 200px
            object-fit: contain
            border-radius: 12px
        .description
            flex-grow: 1
            max-width: 45%
    .footer
        display: flex
        justify-content: flex-end
        align-items: center

@media screen and (max-width: 576px)
    .product
        .body
            flex-direction: column
            .description
                max-width: 100%
