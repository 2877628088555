@import "../../theme/theme"

.container
  min-height: calc( 100vh - 116px )
  overflow: hidden
  display: flex
  flex-direction: column
  align-items: center
  .title
    text-align: center
    margin: 20px 0
  .options
    padding: 20px
    background-color: #FFF
    border-radius: 20px
    align-self: center
    .col
      border-radius: 10px
      background: no-repeat right / cover
      &.customer
        background-image: url('../../assets/images/customer.jpg')
      &.installer
        background-image: url('../../assets/images/wallbox-step-option-installation.jpg')
        background-position-x: center
        .informationSection
          border-bottom-left-radius: 0
        .registerSection
          background-color: #F1F3F5
          padding: 16px
          border-radius: 0 0 10px 10px
          border-top: 1px solid #E0DEDE
          .smallText
            margin: 0
            font-weight: 400
      .informationSection
        font-weight: 500
        width: 65%
        background-color: #F1F3F5
        min-height: 30vh
        border-radius: 10px 20% 20% 10px / 10px 50% 50% 10px
        padding: 16px
        display: flex
        flex-direction: column
        justify-content: space-between
        .descriptionText
          color: $darkGray
.smallLink
  color: #131630!important
  font-weight: 500

@media screen and (max-width: 768px)
  .container
    & .content
      width: 100%
