@import "../../../theme/theme"

.container
  width: 100%
  padding: 20px 60px

.title
  font-weight: 600
  font-size: 24px
  color: $black

.container .text
  color: $darkGray

.options
  margin-top: 40px
  display: flex
  flex-direction: row
  justify-content: space-between

.option
  display: flex
  flex-direction: column
  padding: 15px
  width: 32%
  border: 1px solid $lightGray
  border-radius: 6px
  text-align: center

.optionTitle
  font-weight: 600
  font-size: 18px

.optionDescription
  display: inline-block
  margin-bottom: 16px

.subtitle
  color: #2c3440
  font-weight: 600
  font-size: 18px

.fileCategory
  color: #6b717a
  font-size: 12px
  font-weight: 600
  margin-top: 15px

.date
  color: #bfc2c5
  font-style: italic
  font-size: 12px
  font-weight: 500
  margin-top: -10px