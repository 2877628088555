.formContainer {
  background-color: #fff;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid #eee;
  border-radius: 10px;
}

.form {
  align-items: center;
}

.searchInput {
    min-width: 28rem;
}

.crTagLine {
  padding-left: 5px;
}
