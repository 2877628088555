.container
  position: relative
  border-radius: 12px
  background-color: #FFFFFF
  box-shadow: 0 10px 30px 0 rgba(34,53,66,0.05)
  padding: 20px 24px
  box-sizing: border-box
  border: 1px solid rgba(226, 226, 226, 0.3)

.inner
  display: flex
  flex-direction: column
  align-items: stretch
  cursor: pointer

.text
  margin: 0

.container.small
  display: inline-block
  padding: 20px 20px 20px 46px

  .content
    font-size: 12px
    line-height: 16px
    font-weight: 500
    color: #2c3440

.container.medium
  padding-left: 86px
  max-width: 360px

  .icon
    width: 47px
    height: 47px
    background-size: 23px 23px

.container.fluid
  width: auto
  max-width: none

.container.warning
  .icon
    background-color: rgba(255,179,57,0.12)
    background-image: url("../../../assets/icons/alert-circle-orange.svg")

  .title .text
    color: #FFB339

.container.error
  .icon
    background-color: rgba(255,56,79,0.12)
    background-image: url("../../../assets/icons/alert-circle-red.svg")

  .title .text
    color: #FF384F

.container.success
  .icon
    background-color: rgba(26, 207, 151, 0.12)
    background-image: url("../../../assets/icons/alert-circle-green.svg")

  .title .text
    color: #1ACF97

.container.info
  .icon
    background-color: rgba(28, 110, 247, 0.12)
    background-image: url("../../../assets/icons/alert-circle-blue.svg")

  .title .text
    color: #006CFF

.container.action
  background: #1C6EF7
  .title .text, .content
    color: #FFFFFF

  .icon
    background-color: rgba(255, 255, 255, 0.12)
    background-image: url("../../../assets/icons/alert-circle-white.svg")


.icon, .close
  position: absolute
  border-radius: 50%
  display: block
  background-repeat: no-repeat
  background-position: center

.icon
  width: 17px
  height: 17px
  top: 50%
  left: 20px
  transform: translateY(-50%)

.close
  right: 10px
  top: 10px
  width: 20px
  height: 20px
  border-radius: 50%
  background-image: url("../../../assets/icons/close-gray.svg")
  background-size: 6px
  will-change: background-color
  transition: background-color 0.2s ease-in-out
  cursor: pointer

.close:hover
  background-color: rgba(147,150,176,0.1)

.content,
.title .text
  letter-spacing: 0

.title .text
  font-weight: 600
  font-size: 18px
  line-height: 27px

.title
  margin: 0 0 3px

.content
  font-weight: 500
  font-size: 12px
  line-height: 20px
  color: #A7AEB8
