.title
  font-weight: bold
  font-size: 1.1rem
  margin-bottom: 6px

.container
  display: inline-block
  transform-origin: 0 0
  margin: 0
  padding: 0
  &:global.ant-modal:global
    position: absolute
    top: unset
    bottom: 26px
    left: 50px

.generalInformation
  display: flex
  align-items: stretch
  & .section
    display: flex
    flex-direction: column
    justify-content: space-between
    margin: 12px 12px 0 0

.person
  display: flex
  flex-direction: column
  margin-bottom: 12px

.privacyContainer
  & a:first-child
    margin-right: 14px

.cookiesButton
  display: block
  width: 40px
  height: 40px
  cursor: pointer
  border-radius: 5px
  position: fixed
  left: 10px
  bottom: 60px
  z-index: 30
  background-color: #1C6EF7

.cookiesButton:hover
  opacity: 0.85
  transition-duration: .3s

.icon
  width: 24px
  height: 24px
  margin: 8px

.contactWrap
  margin-bottom: 10px
  display: flex
  align-items: center

.contactAvatar
  margin-right: 7px

.contactName
  display: block
  line-height: 1
  font-size: 16px
  color: #2C3440
  font-weight: 600

.companyName
  font-size: 12px
  color: #A7AEB8
  font-weight: 500

.contacts
  display: block
  color: #1C6EF7
  svg
    margin-right: 14px
    color: #A7AEB8
