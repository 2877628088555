@import "src/theme/theme"

.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.questionList
  width: 100%
  padding: 20px

.editButton
  height: 32px!important

.iconEdit
  width: 18px
  height: 18px
  transform: rotate(180deg)

.answersList
  display: flex
  flex-direction: column

.questionContainer
  margin: 10px 0
  display: flex
  justify-content: space-between
  align-items: center
  .answer
    display: flex
    flex-direction: column
    span
      font-size: 14px
    .questionLabel
      color: $darkGray
    .answerLabel
      font-weight: 600
      color: #172638
