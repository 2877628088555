.content
  padding: 0 40px
  width: 100%

.question
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 30px

.questionText
  margin-left: 20px