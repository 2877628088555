@import "src/theme/theme"

.createContainer
    margin-top: 12px
    display: flex
    justify-content: flex-end

.actions
    display: flex
    & .delete
        color: $red
    & .edit
        color: $blue
    & > span:first-of-type
        margin-right: 10px

.empty
    color: $darkGray

.modalButtons
    display: flex
    justify-content: space-between
    margin-top: 20px

.header
    display: flex
    justify-content: space-between
    align-items: center
    & .title
        font-size: 20px
        font-weight: 500
        margin-bottom: 8px

.tableHeader
    display: flex
    align-items: center

.tableBody
    margin-top: 20px
    padding: 20px 0
    border-radius: 8px
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1)
    & .selectFilter
        display: flex
        margin-left: 8px
    & .filterRow
        display: flex
        padding-left: 8px
    & :global .ant-table .ant-table-thead > tr > th
        font-size: 16px
        color: $black
        text-transform: capitalize
        & .ant-table-column-sorters
            padding: 0
