@import "src/theme/theme"

.nameSection
    padding-bottom: 8px
    border-bottom: 1px solid #F1F3F5
    font-weight: 600
    .name
        display: block
        font-weight: 400
        color: $darkGray
