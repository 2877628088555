@import "../../../theme/theme"

.checkbox:global.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner
  background-color: transparent
  border-color: $lightGray
  border-radius: 4px


.checkbox:global.ant-checkbox-wrapper:hover .ant-checkbox:hover::after,
.checkbox:global.ant-checkbox-wrapper:hover .ant-checkbox::after
  visibility: hidden

.checkbox:global.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner:after
  border: 1.2px solid #fff
  border-top: 0
  border-left: 0
  width: 4px
  height: 8px
  top: 43%
  left: 26%

.checkbox:global.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner
  border-color: #1ACF97


.checkbox:global.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner
  background-color: #1ACF97

.checkbox
  color: $darkGray