$black: #2C3440
$darkGray: #606873

.successBox
  width: 100%
  padding: 60px

.successBox .title
  font-weight: 600
  font-size: 28px
  color: $black

.successBox .text
  color: $darkGray
  white-space: pre-line

.manImage
  display: block
  width: 350px
  height: 350px
  position: absolute
  right: 60px
  top: calc(50%)
  transform: translateY(calc(-50%))