@import "../../../theme/theme"

.term
  margin: 10px 0
  color: $darkGray
  display: flex

.key
  flex: 1

.termValue
  margin-left: 5px
  font-weight: 500
  color: #2C3440
  width: 50%
  overflow-wrap: break-word
