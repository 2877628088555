@import "../../../../../theme/theme"

.progress
    cursor: pointer
    display: flex
    align-items: center
    gap: 16px
    justify-content: space-between
    &>div
        justify-content: space-between
    &.inProgress
        border: 1px solid $warning
        background-color: rgba($warning, 0.2)
    &.waiting
        border: 1px solid $success
        background-color: rgba($success, 0.2)
    .progressBar
        background-color: #fff
        border-radius: 50%
        & :global .ant-progress-text
            color: $primary
    .progressText
        font-weight: 600
        font-size: 16px
    button
        width: 100%
        margin-top: 16px
