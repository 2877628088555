@import "src/theme/theme"

.fileItem
    display: flex
    justify-content: space-between
    background-color: $gray
    margin-bottom: 8px
    align-items: center
    border-radius: 8px
    padding-left: 8px
    .name
        margin-bottom: 0
