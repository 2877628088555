.container
    background-color: #fff
    .poolSelector
        margin-top: 24px
    &, & img
        border-radius: 10px
    .landingDescription
        padding: 0 30px
    img
        width: 100%
