.filters
  margin: 16px 0
  border-radius: 10px
  background-color: #FFF
  padding: 10px
  .header
    display: flex
    justify-content: space-between
    gap: 16px
    z-index: 10
    .showButton
      min-width: 120px
  .filtersList
    transition: all 0.3s ease-in-out
    display: flex
    padding: 1rem 1.5rem
    align-items: center
    justify-content: space-between
    gap: 16px

    & .filtersDiv
      display: flex
      align-items: center
      gap: 1.5rem
    
    &.hidden
      display: none
