@import "../../../theme/theme"

.container
  padding: 15px
  width: 600px
  margin: 0 auto
  background-color: #fff
  border-radius: 8px
  box-sizing: border-box
  margin-left: 10px

.title
  margin-bottom: 30px
  padding-bottom: 12px
  font-size: 16px
  color: #273345
  position: relative

  &:before
    position: absolute
    content: ''
    bottom: 0
    left: -15px
    width: calc(100% + 30px)
    height: 1px
    background-color: #f3f6f8

.currentEmail
  display: inline-block
  margin-bottom: 24px
  b
    color: $blue
    font-size: 600

.actions
  display: flex
  justify-content: space-between
  gap: 16px

.link
  color: $blue
  font-size: 16px
  font-size: 600
  cursor: pointer
  transition: all 0.1s ease-in-out

  &:hover
    color: #40a9ff
