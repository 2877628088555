.container
  position: relative

.container.invalid
  margin-bottom: 22px

.input:global.ant-input-affix-wrapper,
.input:global.ant-input
  outline: none
  box-shadow: none
  line-height: 50px
  padding-left: 11px
  padding-right: 11px
  box-sizing: border-box
  height: 50px
  transition: border 0.2s ease-in-out
  border-width: 1px
  border-style: solid
  border-color: #E7E9ED
  border-radius: 6px
  background-color: #FFFFFF
  width: 100%

.input:global.ant-input,
.input:global.ant-input-affix-wrapper > input.ant-input
  padding-top: 20px
  color: #2C3440
  font-weight: 600
  font-size: 16px

.input:global.ant-input-affix-wrapper-focused,
.input:global.ant-input-affix-wrapper:focus,
.input:global.ant-input:focus
  cursor: auto
  border-color: #1C6EF7

.green:global.ant-input-affix-wrapper-focused,
.green:global.ant-input-affix-wrapper:focus,
.green:global.ant-input:focus
  cursor: auto
  border-color: #1ACF97

.green:global.ant-input-affix-wrapper-focused,
.green:global.ant-input-affix-wrapper:hover,
.green:global.ant-input:hover
  border-color: #1ACF97

.input.invalid:global.ant-input-affix-wrapper,
.input.invalid:global.ant-input
  border-color: #FF384F

.error
  color: #FF384F
  font-size: 12px
  letter-spacing: 0
  line-height: 16px

.icon
  width: 18px
  height: 18px

.noLabel
  height: 40px !important
  padding-top: 10px !important

.noLabel.input:global.ant-input,
.noLabel.input:global.ant-input-affix-wrapper
  height: 40px !important
  padding-top: 5px !important

.noLabel.input:global.ant-input-affix-wrapper > input.ant-input
  padding-top: 0 !important
