@import "src/theme/theme"

.bold
  font-weight: 600
  color: $blue

.container
  background-color: #fff
  padding: 16px
  border-radius: 20px
  .subtitle
    color: $darkGray
    margin-bottom: 10px
  &.landing
    border-radius: 20px
    padding: 0
    .landingImage
      height: 330px
      width: 100%
      object-fit: cover
      border-radius: 20px 20px 0 0 
    .landingContent
      padding: .8rem 1rem
    & :global .ant-btn
      margin-left: 16px

.modalContainer
  width: 50vw!important
  & :global .ant-modal-content
    border-radius: 20px
  .showMoreContainer
    margin-top: 10px
    .landingImage
      width: 100%
      object-fit: cover
      border-radius: 20px 20px 0 0

.organizationContainer
  padding: 16px
  display: flex
  justify-content: space-between
  align-items: center
  .header
    font-size: 14px
    color: $darkGray

.checkbox :global .ant-checkbox-inner
  border-color: #797979

.content
  margin-top: 15px

@media screen and (max-width: 768px)
  .modalContainer
    width: 100%!important
  .container
    margin-top: 20px
    &.landing
      .landingImage
        height: 160px
        border-radius: 0
      .landingContent
        padding: .4rem 1rem
  .submit
    width: 100%
