.datePickerWrapper
  margin: 20px auto
  display: flex
  justify-content: center
  align-items: center

.paragraph
  color: #A7AEB8 !important
  font-size: 14px !important


.paragraphLast
  @extend .paragraph
  margin-top: 19px !important

.title
  font-weight: 600 !important
  font-size: 16px !important

.dateTimeTitle
  @extend .title
  margin-top: 34px !important

.back
  margin-bottom: 20px
  padding-left: 0!important

.notification
  position: absolute
  right: 15px