.section
  width: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center

.title:global.ant-typography
  margin: 0 0 32px
  color: #2C3440
  font-weight: 600
  font-size: 20px
  letter-spacing: 0
  line-height: 30px

