@import "../../../../theme/theme"

.block
  margin-bottom: 30px
  padding-right: 20px
  width: 100%

.blockTitle
  font-weight: 600
  font-size: 16px!important

.list
  margin: 14px 0
  padding: 0
  list-style: none
  width: 100%
