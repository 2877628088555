.container {
  height: unset;
}

.tagRow {
  margin: 1rem auto;
  
  .tagChecked span {
    transform: scale(1.1);
  }

  .tagInput {
    width: 70%;
  }
}

.modal {
  .modalActionBtn {
    font-size: 1.2rem;
    background: none !important;
    &:hover:not([disabled]) {
      transform: scale(1.3);
    }
  }

  .createOrEditTagBtn {
    color: #198754;
    &:hover:not([disabled]) {
      color: #198754 !important
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25)
    }
  }

  .closeBtn {
    margin-top: 1rem;
  }
}

.deleteTagBtn {
  margin-top: 1.5rem;
}

.deleteTagCancelBtn {
  background-color: #ddd;
  color: #333;

  &:hover {
    background-color: #ccc !important;
    color: #222 !important;
  }
}
