.homeCheckRequest
  display: flex
  align-items: center
  justify-content: center
  position: relative
  min-height: 100%
  padding: 14px
  border-radius: 8px
  background: white

.homeCheckRequest p
  margin-bottom: 0

.blankBlock
  height: 100%
  text-align: center
  color: #A7AEB8
  font-weigh: 500
  i
    width: 50px
    height: 50px