@import 'src/theme/theme'

.container
    & > div
        border-bottom: 1px solid $lightGray
        margin-bottom: 8px
    .totalSection
        padding: 8px
        .gross
            font-weight: 600
            font-size: 20px
            margin-right: 6px
        .description
            color: $darkGray
        .net
            font-weight: 500
            font-size: 16px
    .totalPrice
        font-weight: 600
        display: flex
        justify-content: space-between
        .installation, .homeCheck
            margin-right: 28px
    .hardwareItem
        background-color: $lightGray
        margin-top: 10px
        border-radius: 12px
        padding: 4px
        display: flex
        align-items: flex-start
        justify-content: flex-end
        .title
            margin-right: auto
        .price
            align-self: flex-end
            margin-right: 4px
        .coverImage
            height: 60px
            width: 50px
            object-fit: cover
            border-radius: 12px
            margin-right: 8px
