.container
  display: flex
  flex-direction: column
  flex-wrap: wrap
  justify-content: flex-start

.container::after
  content: ''
  flex: auto

.container > .preview:nth-child(-n+5)
  //remove top margin from first row
  //-n+2 - 2 columns per row
  margin-top: 0
