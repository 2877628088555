.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.requestContainer
  border-radius: 10px
  background-color: #FFF

.text
  white-space: pre-line

.questionForm
  width: 100%

  .question
    margin-bottom: 0
    display: flex
    align-items: center

  .questionShort
    @extend .question
    height: 80%

.sectionContainer
  position: relative

.imageContainer
  img
    position: absolute
    right: -130px
    top: 5px
    width: 200px

.descriptionFirstOne
  padding-right: 120px

.textCenter
  text-align: center
  margin-top: 20px

.iconLink
  margin-right: 4px
  margin-left: -2px

.fotoWizardLink
  font-weight: bold
  font-size: 12px

.qrCode
  text-align: center
