@import "../../../theme/theme"

.container
  border-radius: 10px
  background-color: #fff
  padding: 10px

.actionButton
  margin-top: 10px

.text
  margin: 10px 0
  color: $darkGray!important
  font-size: 14px

.buttonText
  margin-top: 6px

.buttonText .small
  display: block

.shareButton
  margin-left: 10px

.datePickerWrapper
  margin: 20px 0
  text-align: center

.dateAcceptButton
  margin-top: 20px

.poolData
  margin-bottom: 20px

.informationBoxText
  margin-left: 5px

.buttonWithTooltip
  color: #FFFFFF
  padding: 0 20px
  height: 40px
  line-height: 40px
  font-size: 14px
  border: none
  border-radius: 6px
  outline: none
  box-shadow: none
  background: none !important

.margin
  margin-top: 15px
  margin-bottom: 10px

.addButton
  padding: 0 4px
  display: inline-block
  white-space: nowrap
  cursor: pointer
  background-color: transparent
  border: none

.submitButton
  margin-right: 16px

.priceFormRow
  margin-top: 16px
