@import 'src/theme/theme'

.todoContainer
    display: flex
    flex-direction: column
    justify-content: space-between
    height: calc(100% - 38px)
    gap: 8px
    .continueButton
        align-self: flex-end
    .stepContainer
        padding: 8px
        border-radius: 5px
        border: 1px solid $gray
        display: flex
        gap: 8px
        align-items: center
        .progressCircle
            width: 20px
            height: 20px
            border-radius: 50%
            border: 1px solid $black
        &.done,&.current
            border-color: $success
            background-color: rgba($success, 0.2)
        &.done
            .progressCircle
                border-color: $success
                background-color: $success
                display: flex
                justify-content: center
                align-items: center
        &.current
            &.required
                border-color: $warning
                background-color: rgba($warning, 0.2)
            .progressCircle
                background-color: #FFF
