.button
  margin-top: 20px

.padding
  margin-top: 10px

.textAreaWrapper
  border-width: 1px
  border-style: solid
  border-color: #E7E9ED
  border-radius: 6px
  background-color: #FFFFFF
  padding: 10px

.faqPageHeading
  margin: 20px 0
  display: flex
  flex-direction: row
  align-items: center

.title
  width: 100%
  margin-right: 20px

.collapse .ant-collapse-content
  overflow: visible !important

.landingMedia
  width: 100%
  max-height: 360px
  display: flex
  align-items: center
  flex-direction: column

  img
    max-height: 300px

  button
    margin: 10px