@import "../../../theme/theme"

@mixin font
  font-weight: 500
  color: #273345 !important
  font-size: 16px

.title
  margin-top: 40px
  margin-bottom: 20px
  @include font

.smallTitle
  @include font
  margin-bottom: 10px!important
  font-size: 14px
  text-align: left

.checkbox
  margin-top: 10px !important
  margin-bottom: 10px !important
  @include font

.button
  margin-top: 20px !important

.avatar
  width: 100%!important
  height: 100%!important
  border-radius: 12px
  object-fit: contain

  div
    width: 100% !important
    height: 100% !important
    svg
      width: 40% !important
      height: 40% !important

img.avatar
  position: absolute

.side
  margin-left: 20px
  width: 250px
  display: flex
  flex-direction: column

.main
  width: calc(100% - 270px)

// Avatar styles
.poolAvatar
  position: relative
  margin-bottom: 30px

.imageContainer
  position: relative
  width: 100%
  height: 160px
  margin-bottom: 10px

.imageContainer
  position: relative

// Color picker styles
.pickerContainer
  margin-bottom: 8px

.buttonsColor
  display: flex
  flex-direction: column
  button
    &:first-child
      margin-bottom: 15px
