@import "src/theme/theme"

.coverImage
    width: 100%
    border-radius: 8px
.optionsContainer
    margin-bottom: 8px
    display: flex
    flex-wrap: wrap
    gap: 16px
    .option
        flex-basis: 30%
        min-width: 300px
        flex-grow: 1
.description
    margin-left: 8px
