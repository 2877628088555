$black: #2C3440
$darkGray: #606873

.container
  display: flex
  justify-content: space-around
  gap: 10px
  flex-wrap: wrap
  align-items: center
  width: 100%
  height: 100%

.content
  width: 390px
  margin-right: 40%

.title
  font-weight: 600
  font-size: 24px
  color: $black

.container .text
  color: $darkGray
  white-space: pre-line

.buttonSkip
  margin-right: 10px

.link
  margin: 10px 0

.manImage
  width: 300px
