.table
  border-radius: 12px
  box-shadow: 0 10px 30px 0 rgba(34, 53, 66, 0.05)
  border: 1px solid rgba(226, 226, 226, 0.3)
  overflow: hidden

.nameCol
  text-align: left

.tooltip
  display: inline-block
  font-size: 17px
  margin-left: 8px
  cursor: help

.formContainer
  background-color: #fff
  padding: 1rem 2rem
  border: 1px solid #eee
  border-radius: 10px


.form
  align-items: center

.formItem label
  height: 100% !important

.formItem
  flex: 1 1 0 !important