.category:global.ant-typography
  color: #2C3440
  font-weight: 500
  font-size: 14px
  font-weight: 500
  letter-spacing: 0
  line-height: 22px
  margin-bottom: 5px

.previews:not(:last-of-type)
  margin-bottom: 14px
