@import "src/theme/theme"
.container
  display: flex
  gap: 16px
  align-items: flex-start

.calendar, .selectedDate
  border: 1px solid $lightGray
  border-radius: 6px

.currentDate
  font-size: 15px

.selectedDate
  font-weight: 600
  font-size: 16px
  text-align: center
  margin: 16px 0
  padding: 8px 0
