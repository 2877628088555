.container
    border-radius: 20px
    box-shadow: 0px 8px 14px 0px #1C6EF71A
    background-color: #F1F3F5
    padding: 16px
    width: calc(100% - 32px)
    height: calc(100% - 32px)
    .header
        display: flex
        justify-content: space-between
        align-items: center
        .title
            display: flex
            .iconContainer
                border: 1px solid #F1F3F5
                border-radius: 4px
                padding: calc(4px - 0.8px)
                width: 20px
                height: 20px
                margin-right: 10px
                .icon
                    margin: 0
    &.backgroundWhite
        background-color: #fff
