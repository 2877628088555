\:global .ant-table-wrapper .ant-table
  color: #2C3440
  //border-radius: 8px

  \:global .ant-table-thead > tr
    & > th, & > td
      font-size: 10px
      font-weight: 600
      background-color: transparent
      color: #A7AEB8
      text-transform: uppercase

  \:global .ant-table-thead
    background-color: white

  \:global .ant-table-column-sorters
    padding: 12px 16px

  \:global .ant-table-tbody > tr:last-child > td
    border-bottom: none

  \:global .ant-table-tbody > tr
    cursor: pointer

.empty
  display: inline-block
  padding: 10px

.tableLink
  color: inherit !important
  margin: -16px
  display: block
  padding: 16px
