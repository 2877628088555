.navLink
  margin-right: 20px
  color: #2a3442
  display: flex
  height: 58px
  justify-content: center
  align-items: center
  font-weight: 500
  padding: 0 10px
  position: relative
  transition-duration: .3s
  min-width: 190px
  text-decoration: none
  gap: 10px

.navLine
  background: #1c6ef7
  padding: 1px
  display: none
  align-self: stretch
  position: absolute
  width: 100%
  left: 0
  top: 56px

.activeNavLink .navLine
  display: block
