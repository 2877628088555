@import "src/theme/theme"

.container
  background: #fff
  border-radius: 12px
  box-shadow: 0 10px 30px 0 rgba(34, 53, 66, 0.05)
  border: 1px solid rgba(226, 226, 226, 0.3)
  padding: 25px
  min-height: 500px
.sidebar
  background-color: transparent
  :global .ant-menu-item-selected
    font-weight: 600
    font-size: 16px
    background-color: rgba($primary, 0.2)
    .anticon
      font-size: 16px

.boxTitle
  font-size: 20px
  font-weight: 500
  margin-bottom: 8px
  .tooltip
    margin-left: 4px
    font-size: 14px
    cursor: help

.tabContent
  padding: 20px
