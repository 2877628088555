@import "../../../theme/theme"

.container
  min-height: 450px

.skeleton
  padding: 0 60px

.text
  font-size: 14px
  color: $black
  padding: 0 60px

.womanImage
  display: block
  width: 300px
  height: 300px
  position: absolute
  top: 55%
  transform: translateY(-50%)
