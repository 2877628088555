@import "../../../theme/theme"

.header .text
  margin-left: 20px

.title
  display: inline-block
  font-weight: 600
  color: $black

.back
  margin-bottom: 20px
  padding-left: 0!important

.flexContainer
  display: flex
  justify-content: space-between
  flex-wrap: nowrap

.editButton .infoIcon
  color: gray!important

.text
  display: inline-block
  color: $darkGray!important
  font-size: 14px

.buttonsCol
  text-align: center

.buttonsCol .small
  margin-top: 15px

.small
  display: block

.buttonsAction
  button
    &:first-child
      margin-right: 10px

.orderInstaller
  margin-right: 10px

.titleSmall
  font-weight: 600
  font-size: 18px

.bundleBlock
  display: flex
  align-items: center

.bundleBlock div,
.bundleBlock span
  flex: 1 1

.productDot
  font-weight: bold
  font-size: 20px

.productName
  font-weight: bold
  margin: 5px 0

.notification
  margin-bottom: 16px
  width: 100%
  .children
    display: flex
    justify-content: space-between

.titleSmallRange
  font-weight: 600
  font-size: 18px
  text-align: center

.possibleDays
  height: 70px

.centerBlock
  text-align: center

.label
  color: #A7AEB8
  padding: 0
  margin: 0

// rest
.heightSeparator
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 16px
  height: 100%

.continueButton
  font-weight: 500
  color: #40a9ff

.container .alert
  margin-bottom: 20px

.location
  display: flex
  justify-content: space-between

  div:first-child
    font-size: 18px
    font-weight: 600

  span:last-child
    font-weight: 600

.additionalInfoList
  margin-top: 16px
  padding: 0
  list-style: none

.additionalInfoEmail
  display: flex

.additionalTitle
  margin-top: 10px
  margin-left: 26px
  font-size: 10px
  color: #a7aeb8
  text-transform: uppercase

.additionalInfo
  position: relative
  font-weight: 500

  .icon
    color: #a7aeb8
    position: relative
    top: 2px

    i
      width: 16px
      height: 16px
      margin: 0

  .info
    position: relative
    padding-left: 10px

    &:first-child
      padding-right: 15px

      &:after
        position: absolute
        content: ''
        top: 7px
        right: 0
        width: 5px
        height: 5px
        background-color: #000
        border-radius: 50%

.rejectedHint
  margin: 10px 0 20px
  display: flex
  align-items: center

.rejectedHint svg
  margin-right: 6px

.priceRange
  margin-left: 8px
  display: flex
  line-height: 22px
  font-size: 11px
  color: #606873
  margin-bottom: 16px
  margin-top: 8px
  justify-content: center
  text-align: left
  span
    font-weight: 600
    text-transform: uppercase

.price
  font-size: 26px
  font-weight: 600
  line-height: 34px
  margin-right: 8px
  color: #313131

.mt2
  margin-top: 2rem 
