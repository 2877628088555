.container :global .ant-modal-content
  border-radius: 8px
  background-color: #FFFFFF
  box-shadow: 0 50px 50px 0 rgba(34,53,66,0.03)
  box-sizing: border-box

.container :global .ant-modal-body
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding: 40px
  box-sizing: border-box

.title:global.ant-typography
  color: #2C3440
  font-weight: 600
  font-size: 20px
  letter-spacing: 0
  line-height: 34px
  text-align: center
  margin: 0 0 4px

.text:global.ant-typography
  color: #A7AEB8
  font-size: 14px
  letter-spacing: 0
  line-height: 22px
  text-align: center
  margin-bottom: 24px

.icon
  margin-bottom: 20px

.button
  width: 100%
