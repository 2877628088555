.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  position: relative
  /* Workaround for this bug: https://bugs.webkit.org/show_bug.cgi?id=26559 */

.header
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #F3F6F8

.title
  height: 50px
  border-bottom: 1px solid #F3F6F8
  box-sizing: border-box
  padding: 0 20px


.noBorder
  border: none

.title .text
  margin: 0
  line-height: 50px
  font-weight: 500
  font-weight: 500
  font-size: 16px

.content
  padding: 20px
  box-sizing: border-box
  overflow: hidden
  overflow-y: auto
  flex: 1
  display: flex
  flex-direction: row
  justify-content: center

.navigation
  padding: 20px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between

.navigation.withoutNav
  padding: 10px

.navigation .nextButtons
  flex: 1
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-end

.arrowIcon
  position: absolute
  bottom: 22px
  left: 50%
  transform: translateX(-50%)

  i
    width: 20px
    height: 20px
