.sidebar
  position: sticky
  top: 20px

.listItem
  color: #a5aebb
  margin: 15px 20px
  cursor: pointer
  font-size: 14px
  transition: all 0.2s ease-in-out
  font-weight: 500
  
  &:first-child
    margin-top: 12px

.subList
  padding-left: 24px
  position: relative
  max-height: 350px
  overflow: hidden

.fade
  overflow-y: scroll
  margin-right: 5px

.fade::after
  content: ''
  height: 40px
  width: 100%
  position: sticky
  background: linear-gradient(0deg, #f3f6f8, transparent)
  bottom: 0
  pointer-events: none
  display: block

.fade::-webkit-scrollbar
  width: 5px !important
  height: 5px !important


.fade::-webkit-scrollbar-track
  border-radius: 10px !important

.fade::-webkit-scrollbar-thumb
  background: transparent !important
  border-radius: 10px !important

.fade:hover::-webkit-scrollbar-thumb
  background: lightgray !important

.subList div:first-child .listItem
  margin-top: 0

.subList div:last-child .listItem
  margin-bottom: 0

.fade div:last-child .listItem
  margin-bottom: -25px

.dot
  display: inline-block
  width: 6px
  height: 6px
  background: #c9cfd5
  border-radius: 50%
  margin-bottom: 2px
  margin-right: 15px
  margin-left: 4px
  transition: all 0.2s ease-in-out

.selected
  color: #0065ff

  .dot
    background: #1c6ef7
    box-shadow: 0 4px 10px 4px rgba(28, 110, 247, 0.1)

.icon
  margin-right: 11px
  display: inline-block

.count
  border-radius: 8px
  background-color: #a5aeba
  width: 23px
  height: 16px
  color: #ffffff
  font-size: 12px
  display: inline-block
  text-align: center
  margin-left: 10px
  line-height: 16px