@import "../../../theme/theme"

.calendar
  border: 1px solid $lightGray
  border-radius: 6px

.container
  display: flex
  justify-content: center
  gap: 16px
  align-items: flex-end

.timeWrapper
  position: relative

.timesWrapper
  position: relative
  padding: 15px 15px 0 15px
  height: 320px
  background-color: #F5F8F9
  border: $borderInput
  border-radius: 8px
  overflow-y: scroll

  &::-webkit-scrollbar
    display: block!important
    width: 4px
    height: 4px

  &::-webkit-scrollbar-thumb
    background: rgba(44,52,64,0.6)
    border-radius: 3px

  &::-webkit-scrollbar-track-piece
    border-radius: 3px
    background: $lightGray

.timesList
  columns: 200px 2
  padding-left: 0

.timeCard
  height: 45px
  background-color: #fff
  border: 1px solid $lightGray
  border-radius: 6px
  width: 200px
  padding: 10px
  justify-content: center
  display: flex
  margin-bottom: 1rem
  font-size: 16px

.activeCard
  background-color: $blue
  color: #fff

.timeCard:hover
  cursor: pointer

.calendar *
  outline: none !important
  text-align: center !important

.calendar *:focus
  outline: none !important

.styleLeft
  background-color: transparent
  float: left
  border: none
  font-size: 12px
  position: absolute
  left: 10px
  top: 20px

.styleRight
  background-color: transparent
  float: right
  border: none
  font-size: 12px
  position: absolute
  right: 10px
  top: 20px

.icon
  color: $blue

.cellStyle
  height: 20px
  vertical-align: top
  width: 20px
  position: relative

.dateStyle
  position: relative
  display: flex
  bottom: 4%
  justify-content: center
  vertical-align: middle
  font-size: 14px

.blueColor
  color: $blue

.timeZone
  padding-top: 17px
  padding-left: 2px
  font-size: 14px
  line-height: 18px
  color: #A7AEB8

.disabledCard
  color: #00000040
  background: #f5f5f5
  cursor: not-allowed !important
