.container
  display: flex
  align-items: center
  position: relative
  cursor: pointer

.header
  border-bottom: 1px solid #f0f0f0
  .name
    display: flex
    align-items: center
    justify-content: space-between

.name
  display: inline-block
  margin-left: 10px
  font-size: 14px
  font-weight: 600

.menu
  display: flex
  flex-direction: column
  align-items: flex-start

.drawer
  :global .ant-drawer-content
    border-radius: 8px 0 0 8px

.menuButton
  text-align: left
  .icon
    margin: 0
