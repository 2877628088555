.title
  margin-bottom: 20px
  font-weight: 500
  color: #273345 !important
  font-size: 16px

.copyButton
  width: 16px
  height: 16px
  margin-right: 5px
  cursor: pointer

.checkbox
  margin-top: 20px !important
  margin-bottom: 20px !important

.calendar
  max-width: 385px

.notification
  margin-bottom: 20px
  box-shadow: none
  border: none

.linkInput
  margin-bottom: 24px

.codeInput
  max-width: 385px