.waitingOffers
    text-align: center
    p
        margin-top: 24px
        margin-bottom: 0
        font-size: 14px
        font-weight: 500
        color: #A7AEB8
.waitingImg
    width: 193px
