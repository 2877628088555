@import "../../../theme/theme"

.container
  padding: 50px
  text-align: center

.strong
  color: $blue

.text
  background-color: #F3F6F8

.title,
.link
  font-weight: 600

.link
  display: inline-block
  margin-top: 10px
  text-decoration: underline

.dropzone
  max-width: 800px
  margin: 40px auto

.files 
  justify-content: center

.files::after
  flex: 0