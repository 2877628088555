@import "src/theme/theme"

.carouselContainer
  & :global
    .slick-list
      user-select: text
      width: calc( 100% - 66px )
      margin: 0 auto
    .slick-next,.slick-prev
      &::before
        content: ''
  .carouselArrow
    width: 32px
    height: 32px
    margin-top: -4px
    background-color: #FFF
    border-radius: 50%
    transition: all 0.3s linear
    display: flex !important
    justify-content: center
    align-items: center
    right: 0
    &.left
      left: 0
      svg
        transform: rotate(180deg)
    svg
      height: 10px
      strike: $primary
    &:hover
      background-color: rgba(0,0,0,0.2)
  .stepContainer
    padding: 0 8px 8px 0
    .step
      padding: 16px
      border-radius: 20px
      display: flex
      align-items: center
      justify-content: space-between
      gap: 8px
      transition: all 0.3s ease-in-out
      cursor: pointer
      width: calc( 100% - 40px )
      .progressBar
        display: none
        & :global .ant-progress-text
          color: $primary
      .stepCounter
        text-transform: uppercase
        color: $darkGray
        font-size: 10px
      .circle
        border-radius: 50%
        font-weight: 600
        font-size: 16px
        line-height: 24px
        text-align: center
        width: 24px
        height: 24px
        color: $darkGray
        margin-bottom: 8px
      .title
        color: $primary
      &.active
        box-shadow: 0px 8px 24px 0px rgba(28, 110, 247, 0.1)
        .circle
          color: #fff
          background-color: $primary
      &.done
        border: 1px solid $success
        background-color: rgba(176, 240, 210, 0.2)
        .circle
          color: #fff
          background-color: $success
      &:hover
        box-shadow: 0px 8px 24px 0px rgba(28, 110, 247, 0.1)
      &>div:last-child
        .progress::after
          display: none

.substep
  font-size: 16px
  display: flex
  gap: 8px
  margin-bottom: 8px
  background-color: transparent
  border: none
  text-align: inherit
  border-radius: 0
  appearance: none
  cursor: not-allowed
  width: 100%
  padding: 0.1rem

  .substepNumber
    text-align: center
    width: 25px
    height: 25px
    border-radius: 50%
    color: $black
  &.clickable
    cursor: pointer
    font-weight: 600
    &:hover
      background-color: $lightBlue
  &.done
    color: $success
  &.active
    color: $primary
    font-weight: 600
    .substepNumber
      background-color: $primary
      color: #FFF

@media screen and (max-width: 576px)
  .progressBar
    display: inline-block!important
