@use "../../theme/theme" as theme

.container .alert
  margin-bottom: 20px

.header .text
  margin-left: 20px

.back
  margin-bottom: 20px
  padding-left: 0!important

.titleContainer
  margin-bottom: 16px

.titleContainer h3
  margin-bottom: 0!important

.title
  display: inline-block
  font-weight: 600
  color: theme.$black

.label
  display: inline-block
  text-align: center
  width: 52px
  height: 16px
  line-height: 16px
  border-radius: 11px
  font-size: 10px
  font-weight: 600
  text-transform: uppercase
  color: #FFFFFF
  background-color: #FF9E56

.publishDate
  display: flex
  align-items: center

.text
  color: #A7AEB8

.exportDateButton
  position: absolute!important
  top: 10px
  right: 0

.exportDateButton button
  display: inline-flex
  align-items: center
