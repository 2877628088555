// colors
$primary: #172638
$black: #2C3440
$gray: #f3f6f8
$darkGray: #8E8E8E
$lightGray: #E7E9ED
$midGray: #DCE0E5
$blue: #1C6EF7
$lightBlue: #E8F0FE
$success: #2D855C
$warning: #ECDC87

$red: #ff384f

// sizes
$container: 1312px
$boxHeight: 620px
$heightHeader: 58px
$installerCardWidth: 210px
$additionalInfoCardWidth: 500px

// borders
$borderRadius: 12px
$borderInput: 2px solid #E7E9ED
$borderRadiusInput: 8px
