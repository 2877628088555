$black: #2C3440
$darkGray: #606873

.container
  height: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  box-sizing: border-box
  width: 390px
  min-height: 300px
  margin-right: 40%

.title
  font-weight: 600
  font-size: 24px
  color: $black

.link
  margin-top: 10px

.womanImage
  display: block
  width: 300px
  height: 300px
  position: absolute
  right: 50px
  top: calc(50% + 20px)
  transform: translateY(-50%)
