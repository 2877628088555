.container :global .ant-modal-content,
.container :global .ant-modal-header
  border-radius: 8px

.caption
  margin-bottom: 20px

.footer
  display: flex
  justify-content: space-between

.actions
  display: flex
  .button:first-child
    margin-right: 15px

.textAreaWrapper
  border: 1px solid #E7E9ED
  border-radius: 6px
  background-color: #FFFFFF
  padding: 10px
  margin-bottom: 20px
