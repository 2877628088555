@import "../../../theme/theme"

.questionsHead
  display: flex

.content
  width: 100%
  margin-top: 16px

.table
  width: 100%

.table thead > tr:first-child th:first-child
  padding-left: 25px

.table thead > tr > th
  font-size: 14px!important
  color: $black!important
  text-transform: unset!important

.type
  display: flex
  align-items: center

  i
    margin-right: 20px

.buttonAction
  display: flex
  justify-content: center
  width: 100%

.questionsTitle
  min-width: 60px
  margin-top: 20px
  padding: 0 20px
  height: 50px
  line-height: 50px
  border-bottom: 1px solid #F3F6F8
  box-sizing: border-box
  text-transform: capitalize

.questionsTitle h4
  margin-bottom: 0
  font-weight: 500
  font-weight: 500
  font-size: 16px

.questions table tr > td,
.questions table tr > th
  border-bottom: none

.questions table tr > th
  padding: 0

.priceRange
  line-height: 22px
  font-size: 11px
  color: #606873
  span
    font-weight: 600
    text-transform: uppercase

.price
  font-size: 14px
  font-weight: 600
  line-height: 16px
  margin-bottom: 4px

.organizationLogo
  margin-right: 10px
  border-radius: 10px
  box-shadow: 1px 1px 5px grey
  margin-right: 5px
