.container :global .ant-modal-content
  border-radius: 8px
  background-color: #FFFFFF
  box-shadow: 0 50px 50px 0 rgba(34,53,66,0.03)
  box-sizing: border-box

.container :global .ant-modal-body
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding: 40px
  box-sizing: border-box

.icon
  height: 60px
  width: 60px

.title
  height: 34px
  color: #2C3440
  font-size: 20px
  font-weight: bold
  letter-spacing: 0
  line-height: 34px
  text-align: center

.text
  height: 22px
  color: #A7AEB8
  font-size: 14px
  letter-spacing: 0
  line-height: 22px
  text-align: center
  margin-top: 10px

.button
  margin-top: 10px
