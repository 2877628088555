
@import "src/theme/theme"
.container
    display: flex
.option
    cursor: pointer
    background-color: #FFF
    font-size: 16px
    transition: 0.3s
    margin-right: 5px
    padding: 5px 20px
    border-radius: 5px
    height: 40px
    border: 1px solid $darkGray
    display: flex
    align-items: center
    &.checked
        background-color: $blue
        border-color: $blue
        color: $lightGray
    &:hover
        border-color: $blue
