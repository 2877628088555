.title
  text-align: center
  margin-top: 32px

.joinButton
  margin-top: 32px

.img
  margin-top: 32px
  width: 300px

.container
  display: flex
  flex-direction: column
  align-items: center
  margin: 0 auto
  width: 600px