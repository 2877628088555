.container
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  min-width: 600px
  box-sizing: border-box
  padding: 10px 0 20px 0

.card
  // 1/2  - 2 columns per row
  // 10px - spacing between columns
  box-sizing: border-box
  margin: 10px 10px 0 0
  width: calc(1/2*100% - (1 - 1/2)*10px)

.card:nth-child(2n)
  margin-right: 0

.container > .card:nth-child(-n+2)
  //remove top margin from first row
  //-n+2 - 2 columns per row
  margin-top: 0

.container::after
  content: ''
  flex: auto

@media screen and (max-width:600px)
  .container
    min-width: auto
