@import "../../../../theme/theme"

.cardContainer
    padding: 10px
    border-radius: 10px
    background-color: $gray
    .title
        margin-bottom: 16px
        display: flex
        :global .ant-typography
            margin-bottom: 0
            margin-top: 0
        img
            object-fit: cover
            margin-right: 8px
            width: 30%
            border-radius: 10px
    .dateLabel
        display: flex
        justify-content: space-between
        color: $darkGray
