@import "../../../theme/theme"

.container
    background-color: $gray
    display: flex
    flex-direction: column
    align-items: center
    min-height: 100vh
    gap: 24px
    .content
        max-width: $container
        width: 100%
        flex: auto
