.form
  padding: 0 60px !important
  width: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center

.title
  margin: 0 0 30px
  color: #2C3440
  font-weight: 600
  font-size: 24px
  letter-spacing: 0
  line-height: 31px

.text
  color: #606873 !important
  font-size: 14px
  letter-spacing: 0
  line-height: 22px
  margin-bottom: 34px !important
