@import "./src/theme/theme"

.icon-left,
.icon-right
  display: inline-flex !important
  flex-direction: row
  align-items: center

.icon-left
  padding-left: 10px !important

.icon-right
  padding-right: 10px !important

.button:global.ant-btn
  border: none !important
  outline: none !important
  box-shadow: none !important
  border-radius: 6px
  font-weight: 600
  font-size: 14px
  padding: 0 20px
  height: 40px
  line-height: 40px
  text-shadow: none

.button:global.ant-btn-sm
  height: 35px
  line-height: 35px

.button:global.ant-btn-lg
  height: 50px
  line-height: 50px
  font-size: 16px

// Primary button styles for different color options
.button.blue:global.ant-btn-primary
  background-color: #1C6EF7
  color: #FFF
  &:disabled
    color: rgba(0, 0, 0, 0.25)
  &:hover
    opacity: 0.85

.button.grey:global.ant-btn-primary
  background-color: #808080

.button.green:global.ant-btn-primary
  background-color: #1ACF97

.button.sky:global.ant-btn-primary
  background-color: rgba(28,110,247,0.1)
  color: #1C6EF7

.button.white:global.ant-btn-primary
  background-color: #FFFFFF
  color: #1C6EF7

.button.dark-blue:global.ant-btn-primary
  background-color: #131630
  color: #FFF
// Secondary button styles
.button:global.ant-btn-secondary
  background-color: #E8F0FE
  color: #1C6EF7

// Link button styles
.button:global.ant-btn-link
  color: #1C6EF7

.button:global.ant-btn-link:hover
  background-color: rgba($darkGray, 0.1)
