@import "../../../../theme/theme"

.filterMultiSelect
  position: relative

.filterMultiSelectField
  display: flex
  align-items: center
  gap: 5px
  padding: 0 12px
  height: 32px
  line-height: 30px
  border: $borderInput
  border-radius: 6px
  background-color: white
  cursor: pointer

.filterMultiSelectFieldBlue
  background-color: #1c6ef7
  color: white

.filterMultiSelectIcon
  display: inline-block
  width: 12px
  height: 12px
  margin-left: 2px
  pointer-events: none
  background-image: url("../../../../assets/icons/filter-arrow-down.svg")
  background-size: cover

.filtersBox
  position: absolute
  top: 30px
  left: 0
  margin: 14px 0
  width: 300px
  background-color: #fff
  border: $borderInput
  border-radius: 6px
  z-index: 10

.filtersBoxSearch
  padding: 10px
  border-bottom: $borderInput

.filtersBoxList
  padding: 10px
  border-bottom: $borderInput
  max-height: 350px
  overflow-y: scroll

.searchField:global.ant-input-affix-wrapper
  height: 26px
  line-height: 26px
  padding: 0 10px
  border-radius: 20px
  border-color: #f3f6f8
  background-color: #f3f6f8

.searchField:global.ant-input-affix-wrapper > input.ant-input
  padding: 0 10px
  font-size: 12px
  background-color: #f3f6f8

.checkbox:global.ant-checkbox-wrapper .ant-checkbox
  top: 0

.checkbox:global.ant-checkbox-wrapper
  margin-bottom: 10px
  display: flex
  align-items: center