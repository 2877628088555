@mixin reset-default-style
    box-shadow: unset
    background-color: #A7AEB8

.textAreaWrapper
    background-color: #FFFFFF
    padding: 10px
    border-radius: 0 0 8px 8px

.wrapper
    border: 1px solid #F1F3F5
    border-radius: 8px
    & .toolbar
        background-color: #F1F3F5
        & :global .rdw-option-wrapper
            background-color: #ffffff00
            transition: .3s all linear
            &:hover, &.rdw-option-active
                @include reset-default-style
        & :global .rdw-dropdown-wrapper
            background-color: unset
            transition: .3s all linear
            &:hover
                @include reset-default-style
                border-radius: 4px
        & .dropdown
            &[aria-expanded="true"]
                background-color: #A7AEB8
            & > a
                color: #000
        & .dropdownContainer
            border-radius: 4px
            border: 1px solid #A7AEB8
            overflow: auto
            &::-webkit-scrollbar
                width: 12px
            &::-webkit-scrollbar-thumb
                background: #A7AEB8
                border-radius: 10px
                border: 3px solid rgba(0, 0, 0, 0)
                background-clip: padding-box
            &::-webkit-scrollbar-track
                border-radius: 10px
                border: 5px solid rgba(0, 0, 0, 0)
                background-clip: padding-box
            &:hover
                box-shadow: unset
