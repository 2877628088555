@import "../../../theme/theme"

.title
  display: inline-block
  font-weight: 600
  color: $black

.titleSmall
  display: inline-block
  font-weight: 600
  color: $black
  font-size: 16px!important

.text
  font-size: 14px
  color: $black

.selectedTime
  margin-bottom: 0!important
  font-weight: 600
  font-size: 18px
  color: $black
  display: flex
  align-items: center

.selectedTime button
  color: #40a9ff !important

.linkButton
  margin-bottom: 30px
  display: inline-block
  font-size: 14px
  color: $blue
  cursor: pointer

.collapse
  \:global
    .ant-collapse-item i
      transition: transform 0.2s ease-in-out

    .ant-collapse-item.ant-collapse-item-active i
      transform: rotate(180deg) translateY(-50%)!important

    .ant-collapse-item.ant-collapse-item-disabled i
      opacity: 0.3

.feedbackButton
  margin-left: auto
  display: flex !important
  margin-top: 40px

.icon
  margin-left: 25%

.buttonDescription
  margin-top: 24px

.customButton:global.ant-btn
  white-space: normal
  line-height: 18px
