@import "src/theme/theme"
.options
    padding: 40px 20px
    display: flex
    gap: 16px

.container
    padding: 16px
    border: 2px solid $gray
    border-radius: 10px
    cursor: pointer
    flex: 1
    &:hover
        box-shadow: 0 11px 19px -19px
        transform: scale(1.01, 1.01)
        transition: all .3s ease-out
    &.checked
        border-color: $blue
        transition: .3s
    & .img
        width: 100%
        margin-bottom: 16px
        max-height: 444px
        border-radius: 15px

.option
    margin-bottom: 10px
    display: flex
    align-items: center
    position: relative
    .icon
        display: flex
        align-items: center
        justify-content: center
        width: 20px
        height: 20px
        border-radius: 50%
        background-color: #e9f1ff
        margin-right: 3px
        img
            object-fit: contain
    &::after
        position: absolute
        content: ''
        top: 23px
        height: 8px
        width: 2px
        left: 9px
        border-radius: 1px
        background-color: #e9f1ff
    &:last-child::after
        display: none

.title:global.ant-typography
    color: $blue

@media screen and (max-width: 768px)
    .options
        flex-direction: column
        align-items: center
