.title
  font-weight: 600
  font-size: 20px
  margin-bottom: 10px

.feedback
  padding: 20px 0
  width: 100%

.feedbackBox
  padding: 0 60px 40px 60px
  margin-bottom: 40px

.feedbackBox:first-child
  border-bottom: 1px solid #f5f8f9

.icon
  width: 26px
  height: 26px
  vertical-align: text-bottom
  margin-left: 0