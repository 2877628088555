.container
  width: 100%
  padding: 10px
  position: relative
  display: flex
  flex-direction: row
  margin-bottom: 10px
  cursor: pointer
  border-radius: 8px
  transition: all 0.1s ease-in-out

.container:hover
  background-color: rgba(lightgrey, 0.2)

.icons
  display: inline-block
  height: 50px
  min-width: 50px
  position: relative
  overflow: hidden
  border-radius: 6px
  background-color: #EFEFEF

.document, .download
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.download
  display: none
  background-color: #EFEFEF
  cursor: pointer

.container:hover .download
  display: flex

.info
  display: flex
  flex-direction: column
  justify-content: center
  margin-left: 10px
  overflow: hidden

.text
  color: #2C3440
  font-weight: 600
  font-size: 12px
  margin-bottom: 0!important
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
