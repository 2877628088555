body {
  min-width: 300px !important;
}

.App-main {
  position: relative;
  width: 100%;
  height: calc(60vw * 0.7);
  margin: 10px auto;
}

.App-main-customer {
  position: relative;
  width: 95%;
  height: 95vh;
  margin: 10px auto;
  margin-bottom: 5vh;
}

.App-control-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
}
.App-control-container.hidden {
  display: none;
}

.App-control-container .ots-video-control {
  width: 50px;
  height: 50px;
  margin: 20px 0 !important;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.App-control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

.App-control-container .ots-video-control.audio:hover,
.App-control-container .ots-video-control.audio.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.App-control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.App-control-container .ots-video-control.take-snapshot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-control-container .ots-video-control.video:hover,
.App-control-container .ots-video-control.video.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.App-control-container .ots-video-control.end-call {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
  background-color: red;
  opacity: 0.6;
}

.App-video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mask {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  background: rgba(45, 27, 144, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mask .react-spinner {
  position: absolute;
}

.App-mask .message {
  font-weight: 200;
}

.App-mask .message.with-spinner {
  position: absolute;
  top: 55%;
}

.App-mask .message.button {
  border: 1px solid white;
  padding: 20px 40px;
  border-radius: 6px;
  background-color: Transparent;
  color: white;
  font-family: sans-serif;
  font-size: medium;
}

.App-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.App-video-container .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.App-video-container .video-container.small {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 160px;
  height: 96px;
  border: 1px solid #fcba00;
  z-index: 2;
}

@media (max-width: 1010px) {
  .App-video-container .video-container.small {
    bottom: 65px;
  }
}

.App-video-container .video-container.small.mobile {
  position: absolute;
  bottom: 65px;
  right: 20px;
  width: 160px;
  height: 96px;
  border: 1px solid #fcba00;
  z-index: 2;
}

.App-video-container .video-container.small.left {
  left: 20px;
  border: 1px solid #00fcc2;
}

.App-video-container .video-container.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
}

.App-video-container .video-container.active-gt2.active-odd .OT_subscriber:first-child {
  flex-basis: 100%;
}

.slick-track {
  margin-left: 5px !important;
}

.Default-layout {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Container {
  width: 90%;
  flex: auto;
  margin: 50px auto 0;
  padding: 0 20px 50px;
  max-width: 80vw;
}

.customerContainer {
  width: 80%;
  flex: auto;
  margin: 50px auto 0;
  padding: 0 20px 50px;
}

.OT_audio-level-meter {
  width: 15% !important;
  border-radius: 8px !important;
}

.OT_audio-level-meter__audio-only-img {
  width: 30% !important;
}

.OT_widget-container {
  border-radius: 8px !important;
}
