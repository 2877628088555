.organizationModal :global .ant-modal-header
  padding: 32px 0
  border-radius: 8px 8px 0 0
  border-bottom: none

.organizationModal :global .ant-modal-title
  text-align: center
  font-size: 20px
  font-weight: 600

.organizationModal :global .ant-modal-body
  padding-top: 0

.organizationModalContainer
  display: flex
  flex-direction: column
  gap: 1rem

.organizationModalDropdown
  height: unset

.container
  width: 100%
  display: flex
  flex-direction: column

.label
  margin-bottom: 12px
  display: inline-block
  font-weight: 600

.field
  margin-bottom: 32px

.emailField
  width: 100%
  margin-right: 16px

.buttonAddOrganization
  position: absolute!important
  bottom: 40px
  right: 50px
  height: 50px!important
  line-height: 50px!important

.buttonCancel
  position: absolute!important
  bottom: 40px
  height: 50px!important
  line-height: 50px!important

.organizationModalDetails
  padding: 9px
  margin-top: 23px

