@import "../../../theme/theme"

.container
  width: 100%
  position: relative
  display: flex
  flex-direction: column

.controls
  display: flex
  justify-content: flex-end
  align-items: center

.controls button:last-child
  margin-left: 6px

.header
  display: flex
  justify-content: space-between

.filters
  margin: 10px -26px 0
  padding: 12px 24px 24px
  background-color: #f5f6f8

.filtersList
  margin-top: 10px
  display: flex
  flex: 1 1 auto

.searchIcon
  margin: 0

.closeButton
  cursor: pointer
  display: inline-block

.clearButton
  padding: 0
  border: none
  background-color: transparent
  color: #1c6ef7
  font-size: 12px
  font-weight: 500
  cursor: pointer

.clearButton:hover
  color: #40a9ff

.clearButton:focus
  outline: none

.tags
  // minus padding to compensate for indentation inside Tabs
  // My Project Pools / {pool}
  margin: 10px -25px 0

  padding: 16px 25px
  display: flex
  background-color: #f5f6f8

// re-write ant styles
.searchButton:global.ant-btn-sm
  display: flex
  justify-content: center
  align-items: center
  padding: 0
  height: 32px
  line-height: 32px
  width: 32px
  border-radius: 6px
  border: solid 1px #e7e9ed!important
  &:hover
    border-color: #1c6ef7!important
    color: #1c6ef7

.filtersButton
  @extend .searchButton

.filtersButton.isActive
  border-color: #40a9ff!important

.tag:global.ant-tag
  height: 24px
  line-height: 24px
  font-size: 10px
  font-weight: 600
  text-transform: uppercase
  background-color: #fff
  border: $borderInput
  border-radius: 6px

.searchFieldBig
  width: 100%

.searchFieldBigInput:global.ant-input-affix-wrapper
  height: 32px
  line-height: 32px
  padding: 0 10px
  border-radius: 6px
  border-color: #f3f6f8

.searchFieldBigInput:global.ant-input-affix-wrapper > input.ant-input
  padding: 0 10px
  font-size: 12px
