.question
  font-size: 20px
  font-weight: bold
  color: #000
  margin-bottom: 20px

.input
  width: 100%

.inputs
  text-align: left
