@import "../../../theme/theme"

.defaultLayout
  position: relative
  background: $gray
  min-height: 100vh
  display: flex
  flex-direction: column
  flex: 1

.container
  flex: auto
  padding: 0 20px 50px
