@import "../../../theme/theme"

.rectangle
  position: relative
  height: 100%
  background-color: white
  border-radius: 8px

.rectangleHeading
  padding: 14px
  border-bottom: 1px solid $gray
  display: flex
  justify-content: space-between
  align-items: center

.reactangleTitle
  color: $black
  font-size: 16px

.rectangleContent
  min-height: calc(100% - 82px)
  padding: 14px

