.container
  position: relative
  border-radius: 6px
  box-shadow: 0 0 0 1px #E7E9ED
  background-color: #FFFFFF
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  font-weight: normal
  color: #2C3440
  overflow: hidden
  box-sizing: border-box
  padding: 15px
  width: 200px
  cursor: pointer
  will-change: box-shadow
  transition: box-shadow 0.2s ease-in-out

.checkmark
  position: absolute
  top: -18px
  right: -18px
  width: 36px
  height: 36px
  border-radius: 100%
  background-color: #1ACF97
  background-image: url("../../../assets/icons/check-white.svg")
  background-repeat: no-repeat
  background-size: 10px auto
  background-position: 6px 21px

.title
  font-size: 16px
  line-height: 18px

.staticTitle
  width: 100%
  overflow: hidden
  text-overflow: ellipsis
  text-align: center

.staticText
  font-size: 16px
  line-height: 18px

.message
  display: block
  width: 100%
  font-size: 12px
  line-height: 16px

.dynamic
  width: 100%

.container.checked
  font-weight: 600
  box-shadow: 0 0 0 2px #1ACF97 !important

.container:hover
  box-shadow: 0 0 0 1px #1ACF97

.container.fluid
  width: auto

.container.input, .container.textarea
  padding: 0
  :global .ant-input
    border: none
    outline: none
    box-shadow: none
    background: transparen
    font-size: 16px
    line-height: 18px
    padding: 16px
    transition: none
    cursor: pointer

.container.input.focused, .container.textarea.focused
  :global .ant-input
    padding: 21px 11px 8px 11px
    color: #2C3440
    font-weight: 600
    font-size: 16px
    line-height: 21px
    letter-spacing: 0
    cursor: auto

.container.select
  padding: 0
  :global
    .ant-select-selector
      border: none !important
      outline: none !important
      box-shadow: none !important
      min-width: 138px !important
      width: 100%

.container.radio
  padding: 11px 11px 11px 41px

  .title
    font-weight: 500
    color: #2C3440
    font-size: 14px

  .message
    font-size: 12px
    line-height: 21px
    color: #606873

  .radioBtn
    position: absolute
    top: 15px
    left: 15px
    line-height: 16px !important

  :global .ant-radio:hover .ant-radio-inner
    border-color: #1ACF97 !important

  :global .ant-radio-checked .ant-radio-inner
    border-color: #1ACF97 !important
    border-width: 4px

  :global .ant-radio-input:focus + .ant-radio-inner,
  :global .ant-radio-input:focus + .ant-radio-inner
    box-shadow: none
    border-color: #E7E9ED

  :global .ant-radio-inner::after
    content: none
