@import 'src/theme/theme'

.wallboxSection
    & :global
        .slick-slider
            display: flex
            position: static
            align-items: center
        .slick-list
            user-select: text
        .slick-next,.slick-prev
            &::before
                content: ''
    .carouselArrow
        margin-top: 0
        transform: none
        position: static
        color: $primary
        font-size: 15px
        line-height: 1.5
        &:hover
            color: rgba($primary, 0.6 )
    .product
        display: flex !important
        gap: 8px
        .information
            width: 55%
            .title
                display: inline-block
                font-weight: 600
                font-size: 20px
                margin-bottom: 10px
            .description
                height: 125px
                overflow-y: auto
                margin-bottom: 1rem

        img
            height: 150px
            width: 100px
            object-fit: cover
            border-radius: 16px
.coverImage
    width: 100%
    object-fit: contain
    border-radius: 6px
