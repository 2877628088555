.options
  margin: 0 auto
  max-width: 600px

.back
  margin-bottom: 20px

.next
  margin-left: 100%
  margin-top: 50px
  transform: translateX(-100%)