.documents
    border-top: 1px solid #ebecf0

.documentRow .download
    display: none

.documentRow:hover .download
    display: block

.documentRow:hover .document
    display: none
