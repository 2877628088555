@import "../../theme/theme"

.container :global .ant-modal-content,
.container :global .ant-modal-header
  background-color: #F3F6F8
  border-radius: 8px

.actions
  width: 100%
  display: flex
  justify-content: flex-end

  .button:first-child
    margin-right: 15px

.link
  color: $blue
  font-size: 16px
  font-weight: 600!important
  cursor: pointer
  transition: all 0.1s ease-in-out

  &:hover
    color: #40a9ff