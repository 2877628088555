@import "../../../theme/theme"

.card
  display: inline-flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-width: $installerCardWidth
  width: $installerCardWidth
  padding: 14px
  text-align: center
  border-radius: 6px
  background-color: rgba(243, 246, 248, 0.8)
  min-height: 200px
  vertical-align: top
  margin-bottom: 10px

  .name
    margin: 15px 0 10px
    font-weight: 600
    font-size: 22px //temporary bigger font instead of avatar
    color: $black

  .header
    margin-top: 15px
    font-weight: 600

  .phone
    max-width: 100%
    margin-bottom: 0
    color: $darkGray

.card.small
  min-height: 120px

  .name
    margin: 0 0 10px

.organizationLogo
  border-radius: 10px
  box-shadow: 1px 1px 5px grey
