@import "./src/theme/theme"

.select:global.ant-select.ant-select-single .ant-select-selector,
.select:global.ant-select.ant-select-multiple .ant-select-selector
  position: relative
  padding: 0 11px
  height: 50px
  line-height: 50px
  border: $borderInput
  border-radius: $borderRadiusInput
  background-color: #FFFFFF
  min-width: 150px
  width: 100%


.select:global.ant-select.ant-select-multiple .ant-select-selector
  height: auto
.select:global.ant-select.ant-select-single .ant-select-arrow,
.select:global.ant-select.ant-select-multiple .ant-select-arrow
  transform: translateY(50%) !important

.select:global.ant-select.ant-select-single.ant-select-focused .ant-select-selector,
.select:global.ant-select.ant-select-multiple.ant-select-focused .ant-select-selector
  border-color: #1C6EF7
  box-shadow: none
  outline: none

.select:global.ant-select.ant-select-single .ant-select-selector .ant-select-selection-item
  color: $black
  font-size: 16px
  letter-spacing: 0
  line-height: 48px


.select:global.ant-select.ant-select-single .ant-select-selection-placeholder
  opacity: 0.65
  line-height: 50px
  font-size: 14px
  color: black

.select:global.ant-select-multiple .ant-select-selection-search
  line-height: 50px
  margin: 0

.select:global.ant-select-multiple .ant-select-selection-item
  height: 32px
  line-height: 28px
  border-radius: 6px
  margin-right: 10px
  background-color: #E7E9ED
  color: #272727
  .ant-select-selection-item-remove
    margin: 1px 4px 0 4px


.icon
  width: 10px
  height: 10px
  margin: 0

.option:global.ant-select-item
  box-sizing: border-box
  color: #2C3440
  font-size: 16px
  line-height: 39px
  padding: 0 12px
  height: 39px
  margin-top: 6px

.option:global.ant-select-item.ant-select-item-option-selected
  color: #2C3440
  background-color: #e7e9ed4d
  font-weight: normal
