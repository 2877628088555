@import "../../../theme/theme"

.container
  width: 100%
  box-sizing: border-box

.titleBig
  font-size: 20px

.titleSmall
  font-size: 16px

.price
  font-size: 22px
  color: $black

.container .text
  color: $darkGray

.top
  margin-bottom: 20px
  display: flex
  flex-wrap: wrap

.topText
  flex: 1 0 0
  padding-right: 30px
  min-width: 100px

.detailsText
  font-size: 16px
  color: $darkGray
  margin-left: 20px

.detailsTitle
  color: $black

.detailsContainer
  margin-bottom: 30px

.price
  line-height: 22px
  font-size: 11px
  color: #606873
  span
    text-transform: uppercase

.additionalInformation
  margin-top: 30px
