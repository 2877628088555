.collapse
    margin-left: -15px !important
    margin-bottom: 16px !important
    &:global.ant-collapse-content
        overflow: visible !important

.sectionHeader:global.ant-typography
    margin-bottom: 24px

.textAreaWrapper
    border-width: 1px
    border-style: solid
    border-color: #E7E9ED
    border-radius: 6px
    background-color: #FFFFFF
    padding: 10px

.buttonContainer
    display: flex
    justify-content: flex-end
