.container
  width: 100%
  min-height: 300px
  border-radius: 8px
  background-color: #fff
  padding: 1rem 1.5rem

.manImage
  width: 100%
  object-fit: fill

.content
  align-self: flex-start
