.icon
  display: inline-block
  background-position: center
  background-size: contain
  background-repeat: no-repeat
  margin: 0 10px

.small
  width: 13px
  height: 13px

.medium
  width: 20px
  height: 20px

.large
  width: 35px
  height: 35px
