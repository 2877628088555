@import "../../../theme/theme"

.container
    text-align: center
.buttons
    display: flex
    justify-content: center
    gap: 10px
    &>div
        width: calc(50% / 2)
    .description
        color: $black
