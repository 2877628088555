.tag:global.ant-tag
  height: 25px
  border-width: 0
  padding: 6px
  box-sizing: border-box
  font-weight: 600
  font-size: 10px
  letter-spacing: 0.5px
  line-height: 13px
  text-align: center
  text-transform: uppercase
  border-radius: 6px

.tag:global.ant-tag-warning
  color: #FFB339
  background-color: rgba(255,179,57,0.15)

.tag:global.ant-tag-error
  color: #FF384F
  background-color: rgba(255,56,79,0.15)

.tag:global.ant-tag-success
  color: #1ACF97
  background-color: rgba(26,207,151,0.2)

.tag:global.ant-tag-default
  color: #A7AEB8
  background-color: rgba(167,174,184,0.15)
