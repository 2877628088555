.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.questionForm
  width: 600px

.question
  margin-bottom: 0
  height: 100%
  display: flex
  align-items: center

.horizontal-layout 
  min-height: 450px

.horizontal-layout-item
  height: 100%

.nextButton
  text-align: end

.alert
  margin-top: 20px!important
