.form
  padding: 0 60px !important
  width: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center

.title
  margin: 0 0 30px
  color: #2C3440
  font-weight: 600
  font-size: 24px
  letter-spacing: 0
  line-height: 31px

.text
  color: #606873
  font-size: 14px
  letter-spacing: 0
  line-height: 22px

.modalButton
  color: #1890ff
  cursor: pointer
  &:hover
    color: #40a9ff

.resetMargin:global.ant-checkbox-wrapper
  margin-left: 0!important

.resetPasswordContainer
  border-radius: 10px
  background-color: #fff
