@import "../../../../theme/theme"

.profileWrapper
  width: 600px
  margin: 0 auto
  .link
    color: $blue
    cursor: pointer
    transition: all 0.1s ease-in-out
    &:hover
      color: #40a9ff

.editProfile
  margin-bottom: 15px
  display: flex
  justify-content: space-between
  align-items: center

  .title
    padding-bottom: 0
    margin-bottom: 0
  .link
    font-size: 14px

.container
  padding: 15px
  width: 600px
  margin: 0 auto
  background-color: #fff
  border-radius: 8px
  box-sizing: border-box

.cardUser
  padding: 30px 20px
  min-height: 624px
  border-radius: 8px

.userInfo
  margin-bottom: 60px
  display: flex
  flex-direction: column
  align-items: center

.userInfoName
  margin-top: 24px
  margin-bottom: 10px
  color: #2c3440
  font-size: 24px
  font-weight: 600

.specialty
  font-size: 16px
  color: #979ca3

.profileList
  list-style: none
  padding-left: 0

.profileListEl
  position: relative
  padding: 15px 0
  display: flex
  justify-content: space-between
  border-top: 1px solid #f3f6f8

.profileListTitle
  color: #979ca3

.alertContainer
  text-align: center!important

.ant-alert-icon
  right: 0!important

.alertError
  font-size: 10px!important
  border-radius: 4px!important

.alertError i
  position: relative!important
  top: 3px
  left: 0

.alertError span
  color: $red!important
  font-weight: 600
