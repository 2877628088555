@import "../../../theme/theme"

.container
  position: relative
  min-height: 380px
  display: flex
  flex-direction: column

.title
  font-size: 20px
  font-weight: bold
  color: #000

.description
  margin-top: 10px
  margin-bottom: 10px
  color: $darkGray

.notification
  margin-bottom: 34px
