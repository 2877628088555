@import "../../../theme/theme"

.container
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center

.coverImage
  border-radius: 10px

.question
  font-size: 20px
  font-weight: bold
  color: #000

.fullHeightQuestion
  margin-bottom: 0
  display: flex
  align-items: center

.answerselection
  height: 100%

.description
  display: flex
  align-items: center
  margin-top: 10px
  margin-bottom: 10px
  color: $darkGray
  white-space: pre-line

.hovered
  cursor: pointer

.answers
  margin-top: 20px
  display: flex
  flex-wrap: wrap
  gap: 8px
  width: 100%
  height: 100%

.notification
  margin: 10px 0

.otherOption
  margin-right: 10px
  margin-top: 10px
  display: flex
  flex-grow: 1
  flex-direction: column

.horizontal-layout
  height: 100%

.horizontal-layout-item
  height: 100%

.row
  width: 100%

.answers .selection
  width: 100%

.hoverIcon
  display: inline-block
  margin-right: 20px
  min-width: 30px
  height: 30px
  background-image: url('../../../assets/icons/camera.svg')
  background-size: cover

.info
  margin-left: 5px

.warning
  margin-bottom: 10px
  span
    display: inline-block
    margin: 0 5px

.alertBox
  margin-top: 20px

.modal
  max-width: unset!important
  width: unset!important
  margin: 0!important
  padding: 0!important
  top: 0!important
  overflow: hidden

.modal :global .ant-modal-content
  width: 100vw
  height: 100vh
  overflow-y: scroll

.modal :global .ant-modal-body
  padding: 34px 24px 24px

.modal :global .ant-modal-centered::before
  content: unset
