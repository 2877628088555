.container :global .ant-modal-content
  border-radius: 8px
  background-color: #FFFFFF
  box-shadow: 0 50px 50px 0 rgba(34,53,66,0.03)
  box-sizing: border-box

.container :global .ant-modal-body
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding: 40px
  box-sizing: border-box

.pickerWrapper
  margin: 30px auto

.react-timekeeper__tb-minute-wrapper
  span
    font-size: 20px !important