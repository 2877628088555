.tag:global.ant-tag
  height: 25px
  border-width: 0
  padding: 6px
  box-sizing: border-box
  font-weight: 600
  font-size: 10px
  letter-spacing: 0.5px
  line-height: 13px
  text-align: center
  text-transform: uppercase
  border-radius: 6px

.tag:global.ant-tag.tenderCancelled,
.tag:global.ant-tag.tenderRejected,
.tag:global.ant-tag.offerRejected,
.tag:global.ant-tag.review
  color: #FF384F
  background-color: rgba(255,56,79,0.15)

.tag:global.ant-tag.offerAccepted,
.tag:global.ant-tag.offerAcceptedHomeCheck,
.tag:global.ant-tag.tenderSubmittedHomeCheck,
.tag:global.ant-tag.tenderSubmitted,
.tag:global.ant-tag.tenderAccepted,
.tag:global.ant-tag.tenderAcceptedHomeCheck
  color: #FFB339 !important
  background-color: rgba(255,179,57,0.15) !important

.tag:global.ant-tag.none
  color: #A7AEB8
  background-color: rgba(167,174,184,0.15)

.tag:global.ant-tag.installationDone
  color: #1ACF97
  background-color: rgba(26,207,151,0.2)

.tag:global.ant-tag.invoiceCheckingSubmitHomeCheck,
.tag:global.ant-tag.invoiceSubmittedHomeCheck,
.tag:global.ant-tag.invoiceAcceptedHomeCheck,
.tag:global.ant-tag.invoicePaidHomeCheck,
.tag:global.ant-tag.invoiceCheckingSubmitInstallation,
.tag:global.ant-tag.invoiceSubmittedInstallation,
.tag:global.ant-tag.invoiceAcceptedInstallation,
.tag:global.ant-tag.homeCheckInvoiceRequested,
.tag:global.ant-tag.invoiceRejectedHomeCheck,
.tag:global.ant-tag.invoiceRejectedInstallation
  background-color: #e8f5fa !important
  color: #1ca3cf !important
