.content
  display: flex
  flex-direction: column
  align-items: stretch
  padding: 0 60px
  box-sizing: border-box
  justify-content: flex-start

.gender
  width: 100%

.section
  flex: 1 0 auto

.form
  height: 100%

.landingTitle
  text-align: center
  margin-top: 32px

.joinButton
  margin-top: 32px

.img
  margin-top: 32px
  width: 300px

.container
  display: flex
  flex-direction: column
  align-items: center