@import "../../../theme/theme"

.container
  width: 100%
  padding: 0 3.75rem
  box-sizing: border-box
@media (max-width: 768px)
  .container
    padding: 0
.option
  padding: 15px 25px
  text-align: center

.link
  padding: 0!important
  margin-top: 18px
  display: inline-block
  color: #1C6EF7

.buttonsBlock
  width: 100%
  margin-top: 20px
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: center

.offerData
  margin-top: 20px
  padding: 0
  list-style: none

.checkboxList
  margin-top: 20px
  display: flex
  flex-direction: column

.checkbox
  margin-top: 4px!important
  margin-left: 0!important

.summary
  margin-top: 20px

.boldText
  font-size: 18px
  color: $black
  text-align: center
  margin-bottom: 40px

.error
  color: #FF384F
  font-size: 12px
  letter-spacing: 0
  line-height: 16px

.price
  margin-top: 8px
  line-height: 22px
  font-size: 11px
  font-weight: normal
  color: #606873
  span
    font-weight: 600
    text-transform: uppercase
