.bold
    font-weight: 600

.detailsButton
    font-size: 14px
    cursor: pointer
    background: #f5f8f9
    height: 50px
    color: #000000b3
    span
        transform: rotate(-90deg)
        margin: 0 10px

.details
    padding: 24px
    border-top: 1px solid #ebecf0

.docTitle
    display: flex
    justify-content: space-between
    align-items: center
    border-top: 1px solid #ebecf0
    margin: 0 -24px
    padding: 10px 24px
    font-size: 16px
    font-weight: 600
