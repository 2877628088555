.container
  min-height: calc( 100vh - 116px )
  display: flex
  flex-direction: column
  & > div
    background-color: #fff
    padding: 16px
    border-radius: 10px
    .password
      margin-bottom: 8px
    .forgot
      color: #131630
      font-weight: bold
    .submit
      margin-top: 8px

@media screen and (max-width: 576px)
  .submit
    width: 100%
