.filterItemContainer
  display: flex
  gap: 5px
  align-items: center

  \:global
    .ant-select
      width: 100% !important
      margin-right: 0 !important

      .ant-select-selector
        border-radius: 6px !important

    .ant-input
      margin-right: 0 !important
      width: 100% !important
      border: solid 1px #E7E9ED

    .FilterMultiSelect
      margin-right: 0!important

.title
  font-size: 14px
  margin-bottom: 0!important
  font-weight: 600
.element
  flex-grow: 1
