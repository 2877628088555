@import "src/theme/theme"

.tag:global.ant-tag
    height: 25px
    border-width: 0
    padding: 6px
    box-sizing: border-box
    font-weight: 600
    font-size: 10px
    letter-spacing: 0.5px
    line-height: 13px
    text-align: center
    text-transform: uppercase
    border-radius: 6px
    &.listed
        background-color: #B0F0D2
    &.unlisted
        background-color: #D4D4D4
.section
    background: #FFF
    border-radius: 8px
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1)
    padding: 20px
    margin-bottom: 20px

.description
    color: $darkGray

.input:global.ant-input
    padding-top: 4px

.totalInput:global.ant-input.ant-input-disabled
    text-align: right
    color: #000

.addOption
    display: flex
    flex-direction: column
    align-items: center

.addItemButton:global.ant-btn
    margin-top: 8px
    width: 90%

.dropzone
    display: flex
    flex-direction: column
    align-items: center
    & > span
        color: $darkGray
    & > .button
        margin: 6px 0
        width: fit-content

.product
    display: flex
    align-items: center
    .imageContainer
        width: 40px
        height: 40px
        object-fit: contain
        margin: 12px 34px 12px 0
