@import "src/theme/theme"
.container p
  margin: 20px auto
  font-size: 14px

.container a
  margin: 0 5px

h3
  font-size: 16px
  margin-top: 10px

h5
  font-size: 14px

.addressWrapper
  margin: 40px 0
  p
    line-height: 0.5

.container
  ol
    li
      margin: 10px 0
      padding-left: 20px


.outlined
  font-weight: bold