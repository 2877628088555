@import "../../../theme/theme"
.container
  display: flex
  justify-content: space-between
  gap: 10px
  .title
    margin-bottom: 10px

.description
  color: $darkGray
  font-size: 14px
  line-height: 22px
  white-space: pre-line

@media screen and (max-width:900px)
  .container
    flex-direction: column
