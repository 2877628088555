.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.confirmation
  width: 400px
  align-items: center
  justify-content: center
  display: flex
  flex-direction: column
  padding: 50px 0
