@import "../../../theme/theme"

.footer
  text-align: center
  flex-shrink: 0
  padding-bottom: 16px
  background-color: #F3F6F8

.inner
  display: flex
  flex-direction: row
  justify-content: center
  margin: auto

.link
  font-size: 12px
  color: $darkGray
  padding: 0 5px
  border-right: 1px solid $darkGray
  text-decoration: none
  &:last-child
    border-right: none
