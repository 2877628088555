@import "../../../theme/theme"

.defaultLayout
  position: relative
  background: $gray
  min-height: 100vh
  display: flex
  flex-direction: column

.container
  width: 100%
  flex: auto
  margin: 0 auto
  padding: 10px 0
  max-width: $container
