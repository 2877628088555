.container
  width: 280px
  height: 50px
  display: flex
  flex-direction: row
  align-items: center
  padding: 0 15px
  box-sizing: border-box
  border-radius: 6px
  background-color: rgba(243,246,248,0.8)

.container.fluid
  width: 100%

.middle
  flex: 1
  overflow: hidden

.left
  margin-right: 10px
  box-sizing: border-box

.right
  margin-left: 10px

.name
  font-size: 14px
  line-height: 17px
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.size
  font-size: 12px
  line-height: 14px
  color: #A7AEB8

.trash
  cursor: pointer
  display: block
  opacity: 0.4
  transition: opacity 0.2s ease-in-out

.trash:hover
  opacity: 1

.trash, .type
  margin: 0
