@import "src/theme/theme"

.outlined
  font-weight: bold

.headerSection
  color: $blue !important
  background-color: $gray !important

.biLingualTable
  table
    thead
      th
        @extend .headerSection
        font-size: 1rem !important
    tbody
      td
        vertical-align: top

.subSection
  @extend .headerSection
  font-size: 1.1rem

.indented
  td
    padding: 16px 38px !important
