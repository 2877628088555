@import "../../../../theme/theme"

.notification
  margin-bottom: 25px
  box-shadow: none
  border: none

.container
  display: flex !important
  flex-direction: column
  justify-content: space-between
  min-height: 448px !important

.buttons
  display: flex
  justify-content: space-between
  margin-top: 24px

.question
  position: relative
  border-bottom: $borderInput
  margin-bottom: 0 !important
  padding-bottom: 24px !important
  padding-top: 24px !important

.closeButton
  position: absolute !important
  margin-left: 100%
  transform: translateX(-100%)

.limitContainer
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center

.limitQuestionTitle
  font-size: 20px
  font-weight: bold
  color: #000
  margin-bottom: 20px

.limitDescription
  margin-bottom: 30px
  color: $darkGray

.limitAnswers
  display: flex
  width: 100%
  flex-direction: column

.dropdownRow
  margin-top: 24px
  margin-bottom: 24px

.addWallbox 
  display: flex !important
  justify-content: flex-end
  align-items: center

.modeCheckbox
  margin-top: 8px !important

.processingFee
  margin-top: 16px !important