@import "../../../../../theme/theme"

.dialog
  padding: 0 12px

.item
  border-radius: 4px
  padding: 8px
  transition-duration: .3s

.item:hover
  background: #f3f6f8

.header
  color: #2c344080
  font-size: 12px
  font-weight: 600
  display: flex
  justify-content: space-between

.content
  display: flex

.text
  line-height: 1.5
  font-size: 16px
  font-weight: 600
  flex: 1
  margin-right: 10px
  overflow-wrap: anywhere
  white-space: pre-wrap

.more
  border-radius: 50%
  width: 24px
  height: 24px
  background: #ffffff
  border: 1px solid #e2e6ec
  display: flex
  justify-content: center
  align-items: center
  color: #a7aeb8
  cursor: pointer
  margin-left: 10px

.answer
  margin-top: 10px

.file
  cursor: pointer

.text span
  font-style: italic
  font-weight: 500
  opacity: 0.5

.message
  display: flex
  border-top: 1px solid #f3f6f8

.message .attachment
  padding: 16px 20px
  cursor: pointer
  vertical-align: text-bottom

.message textarea
  flex: 1
  border: none
  outline: none
  color: #2c3440
  font-size: 16px
  font-weight: 500
  padding: 18px 0
  resize: none

.message .send
  margin: 12px 20px
  cursor: pointer
  color: #1069fb
  font-size: 22px
  background: none
  border: none
  align-self: baseline

.icon
  margin: 0

.fileAction
  width: 20px
  height: 20px
  span
    display: none
    font-size: 20px
    color: #ff4d4f

.fileAction:hover
  .icon
    display: none
  span
    display: inline-block