$black: #2C3440
$darkGray: #606873
  
.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

.questionForm
  width: 600px

.title
  font-size: 14px
  line-height: 22px
  color: #a7aeb8
  margin: 35px 0

.successBox
  width: 100%
  padding: 60px

.successBox .title
  font-weight: 600
  font-size: 28px
  color: $black

.successBox .text
  color: $darkGray
  white-space: pre-line


.manImage
  display: block
  width: 350px
  height: 350px
  position: absolute
  right: 60px
  top: calc(50%)
  transform: translateY(calc(-50%))