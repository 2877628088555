@import "../../../theme/theme"

.container
  width: 100%
  padding: 20px 60px

.title
  font-weight: 600
  font-size: 24px
  color: $black

.cardContainer
  text-align: right

.container .text
  color: $darkGray

.options
  margin-top: 40px
  display: flex
  flex-direction: row
  justify-content: space-between

.option
  display: flex
  flex-direction: column
  padding: 15px
  width: 32%
  border: 1px solid $lightGray
  border-radius: 6px
  text-align: center

.optionTitle
  font-weight: 600
  font-size: 18px

.optionDescription
  display: inline-block
  margin-bottom: 16px

.detailsText
  font-size: 16px
  color: $darkGray
  margin-left: 20px

.detailsTitle
  color: black
  font-weight: 600

.detailsContainer
  margin-top: 5rem

.export
  padding: 0 !important
  color: #40a9ff !important

.centerText
  text-align: center

.videoCallLink
  margin-top: 20px
  text-align: center

  i
    margin: 0 5px 0 0
    width: 14px
    height: 14px
  a
    color: #1C6EF7

  &:hover
    opacity: .9
    a
      color: #1C6EF7
      opacity: .9
