@import "../../../theme/theme"

.container
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center

.question
  font-size: 20px
  font-weight: bold
  color: #000
  margin-bottom: 20px

.description
  margin-bottom: 30px
  color: $darkGray
  white-space: pre-line

.answerRow
  position: relative
  display: flex
  width: 100%
  align-items: center
  margin-bottom: 10px

.answerRow .section
  max-width: 320px
  flex: 1 1 0

.answerRow .textSection
  flex: .5 .5 0
  text-align: center

.removeButton
  position: absolute
  right: -20px
  display: inline-block
  cursor: pointer