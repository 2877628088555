@import "src/theme/theme"

.searchRow
    padding: 8px
    & .selectors
        display: flex
        justify-content: flex-end
        & > div
            border: 1px solid $gray
            border-radius: 4px
            & .status
                width: 112px
            & .category
                width: 160px
                border-right: 1px solid $gray
    & .input:global.ant-input-affix-wrapper :global .ant-input
        padding-top: 0!important
