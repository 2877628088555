@import "../../../theme/theme"

.container
  //padding: 0 60px
  margin-bottom: 22px

.question
  font-weight: 600
  font-size: 20px
  color: $black

.option
  font-weight: 600
  color: $black

.description
  margin-bottom: 34px
  color: #A7AEB8

.dateDescription
  display: inline-block
  margin: 8px 0
  font-weight: 600
  font-size: 14px
  color: #A7AEB8

.either
  margin: 10px 0
  color: $black
  font-weight: 600
  font-weight: bold

.label
  color: $black
  font-size: 16px
  font-weight: 600

.checkboxText
  display: inline-block
  color: #A7AEB8

.container :global
  .ant-picker
    position: relative
    width: 100%!important
    padding: 0 10px
    line-height: 50px
    height: 50px
    border-radius: 6px
    transition: border 0.2s ease-in-out
    border-width: 1px
    border-style: solid
    border-color: #E7E9ED
    background-color: #FFFFFF
    box-sizing: border-box

  .ant-picker-input
    font-weight: 600

  .ant-picker-range-separator
    color: $black
    font-weight: bold

  .ant-picker-active-bar
    display: none

\:global
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
    border-radius: 50%!important
    background: $blue!important
    color: white!important

  .ant-picker-cell-range-start::before,
  .ant-picker-cell-range-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before
    background: rgba(28,110,247,0.15)!important
