.container p
  margin: 20px auto

.number
  font-weight: bold
  width: 50px
  display: inline-block

h4
  font-size: 16px

.container
  ul
    margin: 20px auto 10px
    li
      line-height: 1.8