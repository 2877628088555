.pdfPreviewContainer {
  max-height: 45rem;
}

.iframe {
  width: 100%;
  height: 70vh;
}

.toggleCostOverview {
  align-self: flex-end;
  margin-right: .5rem;
  margin-bottom: .5rem;
}

.costDiv {
  position: fixed;
  bottom: 2px;
  z-index: 999;
  min-width: 34rem;
  overflow-y: auto;
  border: 2px solid #333;
  border-radius: 10px;
  max-height: 240px;
  background-color: #e6f4ff;
  padding: 1rem 0;
}

.actionButtons {
  padding: 0 .5rem;
}

.smallText {
  font-size: .7rem;
}

.actionButton {
  font-weight: bold;
  font-size: inherit;
}
