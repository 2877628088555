.container
  width: 80%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.questionList
  width: 600px

.participationButtons
  display: flex
  flex-direction: row
  margin-top: 16px
  .participateButton
    margin-left: 10px
