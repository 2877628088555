.table
  margin-right: 0px
  margin-left: 0px

.navLinks
  background: #000
  margin-right: -25px
  margin-left: -25px

.icon
  width: 26px
  height: 26px

.crTagLine
  padding-left: 5px
