@import "../../../theme/theme"

.header
  max-width: calc( #{$container} - 32px)
  width: calc( 100% - 32px )
  margin: 0 auto
  padding: 8px 16px
  border-radius: 0 0 10px 10px
  box-shadow: 0 2px 20px 0 rgba(34,53,66,0.03)
  background-color: #FFFFFF
  position: sticky
  top: 0
  z-index: 2
  display: flex
  align-items: center
  justify-content: space-between
  & > div
    display: flex
    align-items: center
  .title
    .logo
      margin-right: 10px
      height: 28px
      object-fit: contain
      border-radius: 5px
    .slogan
      color: #969AA0
      padding-left: 10px
      border-left: 1px solid #969AA0
  .languageSelector
    margin-right: 10px
    .icon
      pointer-events: none !important
    & :global .ant-select-selector
      .ant-select-selection-item
        font-weight: 500
    & :global .ant-select-arrow
      color: #131630
  .link
    text-decoration: none
    font-family: 'Nunito Sans', sans-serif
    color: #131630
    font-weight: 600

  .navLink
    font-family: 'Nunito Sans'
    margin-right: 20px
    color: #2a3442
    display: flex
    height: 58px
    justify-content: center
    align-items: center
    font-weight: 500
    padding: 0 10px
    position: relative
    transition: .3s ease-in-out
    min-width: 190px
    text-decoration: none
    &:hover
      color: #1c6ef7
      & .openIcon
        transform: rotate(180deg)
    &.activeNavLink
      border-bottom: 3px solid #1c6ef7
.subNavItems
  display: flex
  flex-direction: column
  .subNavLink
    color: #131630
    padding: 16px 12px
    justify-content: flex-start
    margin: 0
    font-family: 'Nunito Sans'
    border-radius: 4px
    &.activeSubNavLink
      background-color: #106afb0d
      color: #1c6ef7

.submenu:global.ant-popover
  .ant-popover-inner
    border-radius: 0 0 4px 4px
    box-shadow: 2px 2px 8px 0 rgba(0,0,0,0.1)
  .ant-popover-inner-content
    padding: 0
  .ant-popover-content
    margin-top: -14px
  .ant-popover-arrow
    display: none !important

@media screen and (max-width: 576px)
  .slogan
    display: none
