@use '../../../../../theme/theme' as theme

.container
  border-radius: 10px
  background-color: #fff
  padding: 10px

.cardUser
  padding: 30px 20px
  width: 100%
  min-height: 624px
  border-radius: 8px

.userInfo
  margin-bottom: 60px
  display: flex
  flex-direction: column
  align-items: center

.userInfoName
  margin-top: 24px
  margin-bottom: 10px
  color: #2c3440
  font-size: 24px
  font-weight: 600

.userDescription
  font-size: 16px
  color: #979ca3

.members
  padding-top: 20px
  border-top: 1px solid #f3f6f8
  border-bottom: 1px solid #f3f6f8

.membersTitle
  color: #979CA3
  margin-right: 10px

.membersList
  list-style: none
  padding-left: 0

.membersListEl
  position: relative
  padding: 15px 0
  display: flex
  align-items: center

.membersListName
  margin-left: 16px

.inviteLink
  margin-top: 24px

.inviteLinkText
  display: block
  margin-bottom: 12px
  color: #2c3440
  font-weight: 600

.inviteErrorMessage
  margin-top: 10px
  color: theme.$midGray!important
  text-align: center

.deleteBtn
  margin-left: 20px

.imageContainer
  position: relative
  height: 160px
  margin-bottom: 10px
  margin: 0px auto

.imageContainer
  position: relative

.organizationLogo
  border-radius: 10px
  box-shadow: 1px 1px 5px grey

.sliderLogo
  width: 100%
  margin: auto
  position: relative
  padding: 0px 30px
  display: flex
  justify-content: space-evenly
