.container
    background-color: white
    border-radius: 8px
    font-size: 16px
    min-height: 500px !important

.header
    padding-left: 20px
    padding-right: 20px

.header
    margin: 10px 0
    font-weight: 500

.title
    padding: 14px
    font-weight: 600
    color: #2C3440
    border-bottom: 1px solid #ebecf0

.offer
    border-bottom: 1px solid #ebecf0
    color: #2b3441
    font-size: 12px
    font-weight: 500

.offer:last-child
    border-bottom: 0

.block
    margin: 24px !important

.operatorHandles
    justify-content: space-between
    align-items: center

.offerHeader
    font-weight: 500

.bold
    font-weight: 600

.idTitle
    font-size: 12px
    opacity: .7
    display: inline-block
    margin-right: 24px

.updatedDate
    opacity: .4
    justify-content: flex-end
    display: flex !important

.name
    font-size: 18px
    line-height: 40px

.price
    font-size: 16px
    line-height: 40px

.priceTitle
    line-height: 18px
    opacity: .3
    padding-right: 22px
    font-style: italic

.buttons
    margin-left: 12px
    flex-wrap: nowrap !important

.button
    width: 100%
    justify-content: center
    &:first-child
        margin-right: 15px

.done
    color: #1acf97
    font-size: 16px
    span
        margin-right: 5px

.detailsButton
    font-size: 14px
    cursor: pointer
    background: #f5f8f9
    height: 50px
    color: #000000b3
    span
        transform: rotate(-90deg)
        margin: 0 10px

.details
    padding: 24px
    border-top: 1px solid #ebecf0

.fileCategory
    font-size: 12px
    opacity: .7
    margin-bottom: 8px

.detailsTitle
    line-height: 22px
    opacity: .7
    margin-bottom: 8px

.detailsDate
    line-height: 12px
    color: #52c41a
    padding: 5px 8px
    border-radius: 2px
    border: solid 1px rgba(82, 196, 26, 0.4)
    background-color: rgba(82, 196, 26, 0.1)
    font-weight: 600
    margin-bottom: 8px
    width: fit-content

.detailsDate:last-child
    margin-bottom: 20px

.detailsInfo
    font-size: 16px
    font-weight: 600

.infoColumn
    margin-right: 16px
    margin-bottom: 20px

.documents
    border-top: 1px solid #ebecf0

.documentRow .download
    display: none

.documentRow:hover .download
    display: block

.documentRow:hover .document
    display: none

.paidButton
    width: auto
    margin-left: auto

.tableTitle
    background: #f5f8f9
    text-transform: uppercase
    font-size: 12px
    font-weight: 600
    padding: 5px 60px !important
    p
        margin: 0 !important

.docTitle
    display: flex
    justify-content: space-between
    align-items: center
    border-top: 1px solid #ebecf0
    margin: 0 -24px
    padding: 10px 24px
    font-size: 16px
    font-weight: 600

.dropdownButton
    background-color: #aaaeb31a !important
    margin-left: 12px
    padding: 0 10px !important
    color: #6b7079 !important
    span
        margin: 0

.deleteFile
    color: #ff384f !important

.annotation
    margin-bottom: 20px

.annotation:last-child
    margin: 0

.waitingOffers
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    text-align: center
    p
        margin-top: 24px
        margin-bottom: 0
        font-size: 14px
        font-weight: 500
        color: #A7AEB8

.waitingImg
    width: 193px
    height: 150px

.updatedLastDate
    display: inline-block !important
    font-size: 16px
    opacity: .8
    text-align: right
    .lastDate
        opacity: .6
        font-size: 12px

.tabs :global .ant-tabs-nav
    padding: 0 24px
