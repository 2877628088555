@import '../../../theme/theme'

.waiting
  padding-top: 200px
  position: relative
  color: black
  font-weight: 600

.loader
  width: 100%
  padding: 20px 60px 0