@import '../../../theme/theme'

.container
  height: 58px
  display: flex

.item
  padding: 0 10px
  margin: 0 10px
  position: relative
  display: flex
  justify-content: center
  align-items: center
  font-size: 16px
  font-weight: 500
  cursor: pointer

.item.ellipsis
  @extend .item
  width: 14%
  padding: 0 5px

  .title
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &::before
    top: 57px

.number
  min-width: 25px
  min-height: 25px
  background: $midGray
  border-radius: 50%
  color: #000000
  text-align: center
  line-height: 25px
  margin-left: 10px
  font-size: 12px

.selected::before
  content: ''
  position: absolute
  left: 0
  top: 56px
  padding: 1px
  width: 100%
  background: $blue
  align-self: stretch
