@import "../../../../theme/theme"

@mixin font
  font-weight: 500
  color: #273345 !important
  font-size: 16px

.smallTitle
  @include font
  margin-bottom: 10px!important
  font-size: 14px
  text-align: center
  font-weight: bold

.avatar
  width: 100%!important
  height: 100%!important
  max-width: 600px
  border-radius: 12px
  object-fit: contain

  div
    width: 100% !important
    height: 100% !important
    svg
      width: 40% !important
      height: 40% !important

.installationWizardImageContainer
  flex: 1
  position: relative
  margin-bottom: 3rem

.installationWizardUpload
  display: flex
  justify-content: center
  align-items: center
