@import "../../../../theme/theme"

.container
  height: 100%
  max-height: 500px
  .rectangle
    max-height: calc(100% - 82px)
    .viewContainer
      flex-grow: 1
      overflow-y: auto
      overflow-x: hidden
    .bottom
      flex-shrink: 0
      .selectChat
        border-top: 1px solid $gray
        text-align: center
        color: $darkGray
        font-size: 16px
        cursor: default

.rectangle
  display: flex
  flex-direction: column

.headerContent
  display: flex
  align-items: center
  margin-left: -10px

.backButton
  display: flex
  align-items: center
  margin: 0
  padding: 0
  background-color: transparent
  border: none
  cursor: pointer

.groupIcon
  display: inline-block
  position: relative
  padding: 6px
  margin-right: 8px
  min-width: 25px
  height: 25px
  background-color: #efefef
  border-radius: 50px

.groupIcon i
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  margin: 0
  width: 13px
  height: 13px

.message
  display: flex
  border-top: 1px solid #f3f6f8

.message .attachment
  padding: 16px 20px
  cursor: pointer
  vertical-align: text-bottom

.message textarea
  flex: 1
  border: none
  outline: none
  color: #2c3440
  font-size: 16px
  font-weight: 500
  padding: 18px 0
  resize: none

.message .send
  margin: 12px 20px
  cursor: pointer
  color: #1069fb
  font-size: 22px
  background: none
  border: none
  align-self: baseline

.selectChat
  height: 50px
  line-height: 50px
  border-top: 1px solid $gray
  text-align: center
  font-weight: 500
  color: $darkGray
  font-size: 16px
  cursor: default
