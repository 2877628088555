.formItem:global.ant-form-item
  flex-wrap: nowrap

.formItem :global .ant-form-item-label
  margin-top: 8px

.dashedBorder
  border-style: dashed !important

.remove:global.anticon
  padding: 18px
  color: #fe4358
  border-radius: 6px
  &:hover
    background: rgba(0, 0, 0, 0.018)
