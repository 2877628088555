@import "../../../theme/theme"

.container
  position: relative
  padding: 18px 24px
  border: 1px solid rgba(161, 163, 167, 0.3)
  border-radius: 6px
  background-color: #f8f9fa
  font-size: 14px

.title
  position: relative
  font-size: 16px
  color: #2c3440

.titleText
  display: inline-block
  margin-left: 6px

.buttonClose
  position: absolute
  top: 20px
  right: 10px
  border: none
  background-color: transparent
  cursor: pointer
  opacity: 0.7

.buttonClose:hover
  opacity: 1

.container.green
  border-color: #1acf974d
  background-color: #1acf9714
  .title
    color: #1db988

.container.yellow
  border-color: #ffb3394c
  background-color: #ffb33914
  .title
    color: #f1a52c

.container.red
  border-color: #ff384f4d
  background-color: #ff384f14
  .title
    color: #ff384f

