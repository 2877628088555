.container :global .ant-modal-content,
.container :global .ant-modal-header
  border-radius: 8px
  padding: 10px
  position: relative

.title
  margin-top: 0

.text
  margin: 0

.link
  color: #000000d9
  text-decoration: underline

.close
  width: 30px
  height: 30px
  background: #a7a7a7
  border-radius: 50%
  position: absolute
  top: -40px
  right: 0
  color: #dfe0e2
  font-size: 15px
  line-height: 30px
  text-align: center
  cursor: pointer
  transition-duration: .3s

.close:hover
  background: #8f8f8f

.cookiesButton
  display: block
  width: 40px
  height: 40px
  cursor: pointer
  border-radius: 5px
  position: fixed
  left: 10px
  bottom: 10px
  z-index: 30
  background-color: #1C6EF7

.cookiesButton:hover
  opacity: 0.85
  transition-duration: .3s

.icon
  width: 24px
  height: 24px
  margin: 8px

.collapse
  margin-bottom: 8px !important
  * :global .ant-collapse-content-box
    padding: 0 !important

  * :global .ant-collapse-header
    padding: 12px 0 !important
    font-weight: 500
